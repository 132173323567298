import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper"

import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';

//pdf viewer
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer, SpecialZoomLevel, ViewMode, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//script
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";

// Data
import UtilityService from "services/API/utility/UtilityService";
import MDTypography from "common/components/MD/MDTypography";





const ExpoRichiesteStart = () => {

  const location = useLocation();
  const navigate = useNavigate();



  const [lista_man, setLista_man] = useState([
    { nome_manifestazione: '', iscrizioniAperte: false, pdf_regolamento: '' },
  ]);

  const [manifestazioneScelta, setManifestazioneScelta] = useState('');
  const [accettato, setAccettato] = useState(false);
  const [displayPDF, setDisplayPDF] = useState('stringa_iniziale');

  const defaultLayoutPluginInstance = defaultLayoutPlugin(

  );


  useEffect(() => {
    UtilityService.getManAperte()
      .then(response => {


        if (response.errors) {
          console.log(response.errors);
        } else {
          setLista_man([...response.data]);
          console.log('chiamo carica_pdf:' + response.data[0].nome_manifestazione);
          if (response.data[0] != null && response.data[0].nome_manifestazione.length > 0) {
            carica_pdf(response.data[0].nome_manifestazione);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const avanti = () => {

    //controllo se esiste già una richiesta 
    navigate("/expo_richiestaEdit1", { state: { modo: 'new', soggetto: null, subject: manifestazioneScelta } });

  }

  const handleChangeE = (event) => {
    setAccettato(event.target.checked);

  }

  const annulla = () => {
    navigate("/expo_richieste");
  }

  const cambiato = (e) => {
    console.log('carico:');

    setManifestazioneScelta(e.target.value);
    carica_pdf(e.target.value);

    setManifestazioneScelta(e.target.value);

  }

  const carica_pdf = (nome_man) => {

    console.log('carica_pdf ' + nome_man);
    setManifestazioneScelta(nome_man);
    if (nome_man !== '') {

      UtilityService.seleziona_regolamento_man(nome_man)
        .then(response => {
          //  const filename =  response.headers.get('Content-Disposition').split('filename=')[1];
          var arrrayBuffer = base64ToArrayBuffer(response.data); //data is the base64 encoded string
          var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
          var url = URL.createObjectURL(blobb);

          setDisplayPDF(url);

        });
    }

  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} px={3} spacing={6} >

        <Card>
          <HeaderForm titolo="Richiesta di Iscrizione " exit={annulla} modo='' save={annulla} subject="" />
          <MDBox pt={4} pb={3} px={3} >
            <MDBox>
              <ExpoRichiesteStepper index_attivo={0} />
            </MDBox>
            <MDBox>
              <MDBox pt={4} pb={3} px={3} >
                <FormControl fullWidth>
                  <MDTypography variant="body" htmlFor="manifestazione" shrink={true} >Manifestazione</MDTypography>
                  <NativeSelect fullWidth required inputProps={{ name: 'manifestazione', id: 'manifestazione', }}
                    onChange={cambiato}
                    defaultValue=""
                  >  {lista_man.map((man) => (
                    <option key={man.nome_manifestazione}
                      value={man.nome_manifestazione}>
                      {man.nome_manifestazione}
                    </option>
                  ))}
                  </NativeSelect>
                </FormControl>

              </MDBox>
              <MDBox >

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"  >
                  <div style={{ height: "720px" }}>
                    {displayPDF !== 'stringa_iniziale' &&
                      <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={displayPDF} defaultScale={SpecialZoomLevel.PageFit}
                        ScrollMode={ScrollMode.Page} ViewMode={ViewMode.SinglePage}
                        initialPage={0} />}
                  </div>
                </Worker>


              </MDBox>
              <MDBox>
                <FormControlLabel control={<Checkbox onChange={handleChangeE} />} label="Accetto i termini del regolamento" />
              </MDBox>

              <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                <MDBox sx={{ flex: '1 1 auto' }} />



                <MDButton variant="outlined" color="success" onClick={avanti} sx={{ justifyContent: 'flex-end' }}
                  disabled={!accettato}
                >Avanti </MDButton>


              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
/*
AnagraficaExists.defaultProps = {
    subject: "ALL",
  
  };
  
  // Typechecking props for the Footer
  AnagraficaExists.propTypes = {
    subject: PropTypes.string,
   
  };*/

export default ExpoRichiesteStart;
