/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";


import UtentiService from "services/API/Utenti/UtentiService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox"
import Alert from '@mui/material/Alert';


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";


// Authentication layout components piu largo
import SignupLayout from "layouts/SignupLayout";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';



function ExpoRegister() {

  const validationSchema = Yup.object().shape({

    nome: Yup.string().required('Nome e Cognome richiesti').max(50, 'Lunghezza massima raggiunta'),
    cognome: Yup.string().required('Nome e Cognome richiesti').max(50, 'Lunghezza massima raggiunta'),
    email: Yup.string()
      .required('Email richiesta')
      .email('Email non valida'),
    ragione_sociale: Yup.string()
      .required('Ragione Sociale richiesto')
      .max(100, 'Lunghezza massima raggiunta'),
    telefono: Yup.string()
      .required('Telefono richiesto')
      .max(20, 'Lunghezza massima raggiunta'),

    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),


    privacy: Yup.boolean().required('Privacy richiesto'),

  });

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const richiedi_credenziali = (formData) => {

    setError(null);

    var data = {
      nome: formData.nome,
      cognome: formData.cognome,
      ragione_sociale: formData.ragione_sociale,
      email: formData.email,
      username: formData.email,
      telefono: formData.telefono,
      password: formData.password,
      privacy: formData.privacy,
      attivato: false,
    };



    console.log("saveExpo");
    UtentiService.createExpo(data)
      .then(response => {
        handleResponse(response);
      })
      .catch(errore => {
        handleError(errore);


      });
    handleTop();
  };

  function handleResponse(response) {

    console.log(response.data);
    setSubmitted(true);
    reset({
      nome: '',
      cognome: '',
      ragione_sociale: '',
      telefono: '',
      email: '',
      password: '',
      confirmPassword: '',
      privacy: false,
    });

  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }
  const myref = useRef();

  const [pos, setPos] = useState(false);

  const handleTop = () => {
    myref.current.scrollTop = 0;
    myref.current.focus();
    setPos(false);
  };

  const handleScroll = () => {

    if (myref.current.scrollTop > 50) {
      if (!pos) setPos(true);
    } else {
      if (pos) setPos(false);
    }
  };

  useEffect(() => {
    const temp = myref.current;
    temp.addEventListener("scroll", handleScroll);
    return () => temp.removeEventListener("scroll", handleScroll);
  });


  return (
    <SignupLayout image={bgImage} ref={myref} >
      <Card sx={{ mt: 10, mb: 15 }} >
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FARFIERA
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>

        <MDBox pt={1} pb={3} px={3} component="form" role="form">


          <CardContent>
            <MDBox>
              <MDBox opacity={1} textAlign="center">
                <MDTypography variant="h5" fontWeight="medium" sx={{
                  color: "#388e3c"
                }} mt={1} mb={2} >
                  Registrazione Azienda
                </MDTypography>
              </MDBox>
              {((submitted) || (error !== null)) &&
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" ref={myref} mb={4}>

                {(submitted) &&
                  <MDBox textAlign="center">
                  <Alert severity="success">Dati salvati con successo! Ti è stata inviata una email per attivare l'account!</Alert>
                  </MDBox>
                  }
                {error !== null &&
                <MDBox textAlign="center">
                  <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                  </MDBox>
                }
              </MDBox>
              }
              {(!submitted) &&
                <MDBox>
                     <MDBox opacity={1} textAlign="center" mb={4}>
                <MDTypography variant="button" sx={{
                }} mt={1}  >
                  Il seguente modulo ti permetterà di richiedere le credenziali per entrare nell'Area Riservata e poter compilare la Proposta di Partecipazione
                </MDTypography>
              </MDBox>


                  <MDBox mb={2}>
                    <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' {...register('ragione_sociale')} required fullWidth
                      error={errors.ragione_sociale ? true : false} InputLabelProps={{ shrink: true }} />

                  </MDBox>

                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="text" label="Cognome Referente" name='cognome' fullWidth required {...register('cognome')}
                        error={errors.cognome ? true : false} InputLabelProps={{ shrink: true }}
                      />
                      {errors.cognome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cognome?.message}</span> : ''}
                    </MDBox>
                    <MDBox>
                      <MDInput type="text" label="Nome Referente" name='nome' fullWidth required {...register('nome')}
                        error={errors.nome ? true : false} InputLabelProps={{ shrink: true }}
                      />
                      {errors.nome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome?.message}</span> : ''}
                    </MDBox>
                  </MDBox>


                  <MDBox mb={2}>

                    <MDInput type="text" label="Telefono" fullWidth required name='telefono' {...register('telefono')} InputLabelProps={{ shrink: true }} error={errors.telefono ? true : false} />
                    {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                  </MDBox>
                  <MDBox mb={2} >
                    <MDInput type="text" label="Email(Username)" fullWidth required name='email' {...register('email')} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                    {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
                  </MDBox>


                  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="password" label="Password" fullWidth id="password" required
                        name="password" {...register('password')} InputLabelProps={{ shrink: true }} />
                      {
                        errors.password ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.password?.message}</span> : ''
                      }
                    </MDBox>
                    <MDBox>
                      <MDInput type="password" label="Conferma Password" fullWidth {...register('confirmPassword')} name="confirmPassword" InputLabelProps={{ shrink: true }} />
                      {
                        errors.confirmPassword ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.confirmPassword?.message}</span> : ''
                      }
                    </MDBox>
                  </MDBox>




                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <MDBox>

                      <ReactHookFormCheckBox
                        label={<MDBox>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;Do il consenso per&nbsp;
                          </MDTypography>
                          <Link to={'https://www.umbriafiere.it/privacy-policy'} target="_blank">
                            <MDTypography
                              variant="button"
                              fontWeight="bold"
                              color="info"
                              textGradient
                            >
                              Termini and Condizioni
                            </MDTypography></Link>
                        </MDBox>}
                        name="privacy"
                        control={control}
                      />
                      {errors.privacy ? <span style={{ ml: '5px', color: 'red', fontSize: '12px' }}>{errors.privacy?.message}</span> : ''}
                    </MDBox>
                  </MDBox>

                </MDBox>
              }

            </MDBox>
          </CardContent>
          {(!submitted) &&
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit(richiedi_credenziali)} type="submit">
              Richiedi le credenziali
            </MDButton>
          </MDBox>}
          <MDBox mt={3} mb={1} textAlign="center">
            <Link to={`/login`}>
              <MDTypography

                variant="button"
                color="success"
                fontWeight="medium"
                textGradient
              >
                Torna al login
              </MDTypography></Link>
          </MDBox>

        </MDBox>

      </Card>
    </SignupLayout>
  );
}

export default ExpoRegister;
