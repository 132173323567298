import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import Alert from '@mui/material/Alert';

// Data
import LocDataService from "services/API/Comuni/ComuniService";

function ElencoLocalita() {

  const [localita, setLocalita] = useState([]);

  useEffect(() => {
    //console.log("retrive op");
    retrieveLoc();

  }, []);

  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const retrieveLoc = () => {
    LocDataService.getAll()
      .then(response => {
        setLocalita(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };

  const locAttributes = []
  localita.forEach(loc => {
   
    locAttributes.push({
      cap: loc.cap,
      localita: loc.localita,
      provincia: loc.sigla_provincia,
      action: (
        <Link  to="/localitaEdit" state={{modo:'edit',soggetto: loc }}
       >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      ),
     
    })
  });


  const LocTableData = {
    columns: [
      { Header: "Cap", accessor: "cap", width: "30%", align: "left" },
      { Header: "Localita", accessor: "localita", align: "left" },
      { Header: "Provincia", accessor: "provincia", align: "left" },
      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: locAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Localita" add="/localitaEdit" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

                  <Alert severity="success">Località salvata con successo!</Alert>}
                   {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Località eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={LocTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ElencoLocalita;
