
import HorizontalLinearStepper from "common/components/mycomponents/HorizontalLinearStepper"
import { StyledEngineProvider } from '@mui/material/styles';
import PropTypes from "prop-types";



const mysteps = ['Accetta il regolamento', 'DATI Anagrafici', 'Tipologie Espositive e Tariffe','Conferma Richiesta e Invia'];

function ExpoRichiesteStepper({index_attivo

  }) {
    return (
        <StyledEngineProvider injectFirst>
        <HorizontalLinearStepper steps={mysteps} index_attivo={index_attivo} />
        </StyledEngineProvider>
    );

  }
  

ExpoRichiesteStepper.defaultProps = {
    index_attivo:0
  };
 
  ExpoRichiesteStepper.propTypes = {
    index_attivo:PropTypes.number
  };
  
  export default ExpoRichiesteStepper;
  