import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import authHeaderMultipart from 'services/API/Auth/AuthHeaderMultipart';

const getManAperte = () => {
    return axios.get(axios.defaults.baseURL + '/utility/manifestazioni_aperte', { headers: authHeader() });
}
const getAllManifestazioni = () => {
    return axios.get(axios.defaults.baseURL + '/utility/manifestazioni', { headers: authHeader() });
}
const createMan = (data) => {   
    return axios.post(axios.defaults.baseURL + '/utility/manifestazione_ins',data, {
        headers: authHeaderMultipart()
    })  
   };

   const updateMan = (nome, data) => {
    console.log("update= "+nome);
    console.log("data= "+data.pdf_regolamento);
   


    return axios.post(axios.defaults.baseURL + `/utility/manifestazione_upd/${nome}`, data,{headers: authHeaderMultipart()});
  };
  const seleziona_dettagli_man =(nome)=>{

    console.log("seleziona_dettagli= "+nome);
    return axios.get(axios.defaults.baseURL + `/utility/manifestazione_seleziona_dettagli/${nome}`,{headers: authHeader()});

}
const seleziona_regolamento_man =(nome)=>{

    console.log("seleziona_regolamento= "+nome);
    const response= axios.get(axios.defaults.baseURL + `/utility/manifestazione_regolamento/${nome}`,{headers: authHeader()}, {responseType: 'blob'},);
console.log(response);
return response;
}
const getAllCodiciM=()=> {
    console.log(authHeader());
      return axios.get(axios.defaults.baseURL + `/professioni/getAllCM`);
}


const download_file =(nome)=>{

    console.log("seleziona_pdf= "+nome);
 const response= axios.get(axios.defaults.baseURL + `/utility/downloadFile/${nome}`,{headers: authHeader()}, {responseType: 'blob'},);
console.log("RESPONSE="+response);
return response;
}

const UtilityService = {
    getManAperte,getAllManifestazioni,
    createMan,
    updateMan,
    getAllCodiciM,
    seleziona_dettagli_man,
    seleziona_regolamento_man,download_file
};

export default UtilityService;
