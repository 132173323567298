
import BasicLayout from "layouts/ErrorBasicLayout";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDButton from "common/components/MD/MDButton";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import error404 from "common/assets/images/errors-images/404-error.png";

function NotFoundComponent() {


    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox mx={2}
                    mt={0}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2}>
                        <Grid item xl={5}>
                            <MDBox>
                                <Grid container spacing={3} direction="row">
                                    <Grid item xl={4}>
                                        <MDTypography variant="h1" color="primary" mt={1}>
                                            4 </MDTypography></Grid>
                                    <Grid item xl={4}>
                                        <MDTypography variant="h1" color="error" mt={1}>
                                            0 </MDTypography></Grid>
                                    <Grid item xl={3}>
                                        <MDTypography variant="h1" color="success" mt={1}>
                                            4 </MDTypography></Grid>
                                </Grid>

                            </MDBox>
                            <MDBox><MDTypography variant="h2" color="primary" mt={1}>
                                NOT FOUND</MDTypography>

                                <MDTypography variant="h4" color="primary" mt={1}>
                                    Non riusciamo a trovare la pagina che stai cercando!</MDTypography>

                            </MDBox>
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="success" href="/login">
                                    Go to Login
                                </MDButton>
                            </MDBox>
                        </Grid>
                        <Grid item xl={7}>
                            <img src={error404} width='500px' alt="" />
                        </Grid>

                    </Grid>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}
export default NotFoundComponent;