export default function authHeaderPDF() {

  
  const user = JSON.parse(localStorage.getItem('user'));
  

    if (user && user.accessToken) {

   

       return {  Accept:"application/json ,text/plain, */*",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + user.accessToken      };

    } else {
      return {};
    }
  }

    