
import BasicLayout from "layouts/ErrorBasicLayout";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDButton from "common/components/MD/MDButton";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import error403 from "common/assets/images/errors-images/9264746.jpg";

function ForbiddenComponent() {


    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox mx={2}
                    mt={0}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2}>
                        <Grid item xl={7}>
                       
                            <MDBox><MDTypography variant="h2" color="primary" mt={1}>
                                    VERIFICATION ERROR</MDTypography>
                                    
                                    <MDTypography variant="h4" color="primary" mt={1}>
                                  Errore nella verifica dell'account!</MDTypography>    

                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" href="/login">
               Go to Login
              </MDButton>
            </MDBox>
                        </Grid>
                        <Grid item xl={5}>
                        <img src={error403} width='300px' className="img-fluid" alt="" />
                        </Grid>

                    </Grid>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}
export default ForbiddenComponent;