import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"
import HeaderForm from "layouts/components/HeaderForm";
import Alert from '@mui/material/Alert';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Data
import UtilityService from "services/API/utility/UtilityService";



function ElencoManifestazioni() {

  const [manifestazioni, setManifestazioni] = useState([]);

  useEffect(() => {

     UtilityService.getAllManifestazioni() 
      .then(response => {
        setManifestazioni(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  
  }, []);



   //per passarmi la variabiule se ho salvato e far comparire l'alert
   const location = useLocation();
   //console.log(location);
 
   const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state
 
  const manAttributes = []
  manifestazioni.forEach(man => {
    
    manAttributes.push({
      nome_manifestazione: man.nome_manifestazione,
      iscrizioni_aperte: man.iscrizioni_aperte,
       action: (
        <Link  to="/manifestazioneEdit" state={{modo:'edit',soggetto: man,subject:'' }}
       >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      )
    })
  });
  const manTableData = {
    columns: [
      { Header: "Nome Manifestazione", accessor: "nome_manifestazione", width: "50%", align: "left" },
      { Header: "iscrizioni_aperte", accessor: "iscrizioni_aperte_string", width: "10%", align: "left" },
     { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: manAttributes,


  };
  
  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Manifestazioni" add="/manifestazioneEdit" modo="vis" subject='' />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

<Alert severity="success">Manifestazione salvata con successo!</Alert>}
 {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Manifestazione eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={manTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
                
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}

ElencoManifestazioni.defaultProps = {

};

// Typechecking props for the Footer
ElencoManifestazioni.propTypes = {

 
};

export default ElencoManifestazioni;
