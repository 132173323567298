import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import AuthService from 'services/API/Auth/AuthService.js';
import authHeaderMultipart from '../Auth/AuthHeaderMultipart';
import authHeaderPDF from '../Auth/AuthHeaderPDF';

const getMyRichieste=()=> {
    console.log("getMyRichieste "+AuthService.getCurrentUser().id);
   
    return axios.get(axios.defaults.baseURL + `/expo_richieste/all/${AuthService.getCurrentUser().id}`,{headers: authHeader()});
}
const getAllRichieste=(man, stato)=> {
   // console.log("getAllRichieste ");
    return axios.get(axios.defaults.baseURL + `/expo_richieste/all/${man}/${stato}`,{headers: authHeader()});
}
const create = (data) => {   
    return axios.post(axios.defaults.baseURL + '/expo_richieste/ins',data, {
        headers: authHeader()
    })  
   };
   const update = (id, data) => {
    console.log("update= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/upd/${id}`, data,{headers: authHeader()});
  };

  const remove = (id) => {
    console.log("remove= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/remove/${id}`,null,{headers: authHeader()});
  };
  const invia = (id, data) => {
    console.log("invia= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_richieste/send/${id}`, data,{headers: authHeaderMultipart()});
  };

const seleziona_dattagli =(id)=>{

    console.log("seleziona_dettagli= "+id);
    return axios.get(axios.defaults.baseURL + `/anagrafica/seleziona_dettagli/${id}`,{headers: authHeader()});

}
const seleziona =(id)=>{

    console.log("seleziona= "+id);
    return axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona/${id}`,{headers: authHeader()});


}

const selezionaPDF =(id)=>{

    console.log("seleziona_pdf= "+id);
 const response= axios.get(axios.defaults.baseURL + `/expo_richieste/seleziona_pdf/${id}`,{headers: authHeader()}, {responseType: 'blob'},);
console.log("RESPONSE="+response);
return response;
}
/*const download_pdf =(id)=>{

    console.log("download_pdf= "+id);
//let response= axios.get(axios.defaults.baseURL + `/expo_richieste/jasper/${id}`,{headers: authHeader()}, {responseType: 'blob'},);
let response= axios.get(axios.defaults.baseURL + `/expo_richieste/item-report/pdf`,{headers: authHeaderPDF()}, {responseType: 'blob'},);
console.log(response);
return response;
}*/

const download_pdf =(id)=>{

    console.log("download_pdf= "+id);
const response= axios.get(axios.defaults.baseURL + `/expo_richieste/jasper/${id}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}

const ExpoRichiesteService = {
    getMyRichieste,
    getAllRichieste,
    create,
    update,invia,
    remove,
    seleziona,
    seleziona_dattagli,selezionaPDF,
    download_pdf
}

export default ExpoRichiesteService;