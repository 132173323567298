/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, {useState,useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import LogoAgri2025 from "common/assets/images/logos/logo_2025.png"
//import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
//import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
////import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
//import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
//import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import VisitatoriBox from "pages/riservata/visitatori/VisitatoriBox";

import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import SimpleBlogCard from "common/components/implemented/Cards/BlogCards/SimpleBlogCard";

//import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import AuthService from 'services/API/Auth/AuthService.js';


function Dashboard() {
//  const { sales, tasks } = reportsLineChartData;
//const[userReady,setUserReady]= useState(false);
//const[currentUser,setCurrentUser]= useState({id:0,username:'',email:'',roles:[]});




  return (
    <DashboardLayout>
      <DashboardNavbar  />
      <MDBox py={3}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
              {AuthService.isAdmin() && <VisitatoriBox />}            </Grid>
                <Grid item xs={12} md={6} lg={4}>
             {/* <OrdersOverview />*/}
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
            {AuthService.isExpo() &&
              <MDBox mb={1.5}>
              
              <SimpleBlogCard
  image={LogoAgri2025}
  title="Agriumbria 2025"
  description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis."
  action={{
    type: "internal",
    route: "/expo_richieste",
    color: "success",
    label: "Chiedi di partecipare!"
  }}
/>

            </MDBox>}
          </Grid>
          
           {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
            <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>*/}
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
             <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>*/}
         {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>*/}
     {/*   <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
              />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
              <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
               <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
              />
              </MDBox>
            </Grid>
       
        </MDBox>*/}
   </Grid>

<MDBox>

</MDBox>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
