import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';



const create = (data) => {
     console.log(data.id_professione);  
    return axios.post(axios.defaults.baseURL + '/visitatori/ins',data)  
   };


   
const VisitatoriService = {

    create,
    
};

export default VisitatoriService;