import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"

import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import UtilityService from "services/API/utility/UtilityService";

function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);
  const [filtro_man, setFiltro_man] = useState(['']);
  const [filtro_stato, setFiltro_stato] = useState(['INVIATA']);


  const [lista_man, setLista_man] = useState([
    { nome_manifestazione: '', iscrizioniAperte: false, pdf_regolamento: '' },
  ]);


  useEffect(() => {
    UtilityService.getManAperte()
      .then(response => {
        if (response.errors) {
          console.log(response.errors);
        } else {
          setLista_man([...response.data]);
          setFiltro_man([response.data[0].nome_manifestazione]);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    ExpoRichiesteService.getAllRichieste(filtro_man, filtro_stato)
      .then(response => {
        setProposte(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  }, [filtro_man, filtro_stato]);

  const cambiatoMan = (e) => {
    //console.log('carico:');
    setFiltro_man(e.target.value);

  }
  const cambiatoStato = (e) => {
  //  console.log('carico:');
    setFiltro_stato(e.target.value);

  }

  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox>
          <Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}
          >
          <MDButton variant="outlined" color={"success"} iconOnly={true} >
          <Icon>edit</Icon>
        </MDButton>   </Link>
          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );
      if (prop.stato === "IN COMPILAZIONE") {
        action = (

          <MDBox><Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}
          >

            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </MDBox>
        );


      }




      ppAttributes.push({
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_inserimento: prop.string_data_ins,
        data_modifica: prop.string_data_agg,
        tipologia_espositiva_string:prop.tipologia_espositiva_string,
        settore_merceologico_string:prop.settore_merceologico_string,
        stato: prop.stato,
        action: action,

      })
    });
  }
  const ppTableData = {
    columns: [
      { Header: "Protocollo", accessor: "protocollo", width: "5%", align: "left" },
      { Header: "Azienda", accessor: "azienda", width: "15%", align: "left" },
      { Header: "Data Inserimento", accessor: "data_inserimento", align: "left" },
      { Header: "Data Ultimo Aggiornamento", accessor: "data_modifica", align: "left" },
      { Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string", align: "left" },
      { Header: "Settore Merceologico", accessor: "settore_merceologico_string", align: "left" },
      { Header: "Stato", accessor: "stato", align: "left" },
      { Header: "Azioni", accessor: "action", align: "center", width: "5%", enableGlobalFilter: false },

    ],

    rows: ppAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Richieste di Partecipazione" add="/expo_richiesteStart" modo="vis" save="" annulla="" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Richiesta salvati con successo!</Alert>}
                {(state != null && state.submitted && (state.salvato === 'SENT')) &&

                  <Alert severity="success">Richiesta di partecipazione inviata con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Richiesta eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={4} pb={3} px={3} >
                <FormControl >
                  <MDTypography variant="button" htmlFor="manifestazione" shrink={true} >Manifestazione</MDTypography>
                  <NativeSelect  required inputProps={{ name: 'manifestazione', id: 'manifestazione', }}
                    onChange={cambiatoMan}
                    defaultValue=""
                  >  {lista_man.map((man) => (
                    <option key={man.nome_manifestazione}
                      value={man.nome_manifestazione}>
                      {man.nome_manifestazione}
                    </option>
                  ))}
                  </NativeSelect>
                </FormControl>
                <FormControl >
                  <MDTypography variant="button" htmlFor="stato" shrink={true} >Stato richiesta</MDTypography>
                  <NativeSelect  required inputProps={{ name: 'stato', id: 'stato', }}
                    onChange={cambiatoStato}
                    defaultValue="INVIATA"
                  >  
                  <option key="IN COMPILAZIONE" value="IN COMPILAZIONE">IN COMPILAZIONE</option>
                  <option key="INVIATA" value="INVIATA">INVIATE</option>
                  </NativeSelect>
                </FormControl>
              </MDBox>

              {(proposte != null && proposte.length > 0) &&
                <MDBox pt={3}>
                  <DataTable canSearch={true}
                    table={ppTableData}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    pagination={mypagination}
                    noEndBorder
                  />      </MDBox>}
              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in archivio. Clicca su Nuovo per crearne una!
                  </MDTypography></MDBox>
              }



            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
