
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";

import PropTypes from "prop-types";

function ExpoRichiesteVis({ prop }) {
  return (
    <MDBox>
      <MDBox mb={4}>

        <MDButton variant="gradient" color="success" fullWidth>
          <MDTypography variant="h5" fontWeight="regular" sx={{
            color: "#FFFFFF"
          }}>DATI ANAGRAFICI</MDTypography>
        </MDButton>
      </MDBox>

      <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
        <MDBox>
          <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth disabled value={prop.ragione_sociale} />
        </MDBox>
        <MDBox>
          <MDInput type="text" label="Legale Rappresentante" name='rappresentante_legale' fullWidth disabled value={prop.rappresentante_legale} />
        </MDBox>
      </MDBox>

      <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
        <MDBox >
          <MDInput type="text" fullWidth disabled label="Indirizzo" required name='indirizzo' value={prop.indirizzo} />
        </MDBox>
        <MDBox>
          <MDInput type="text" fullWidth disabled label="civico" required name='civico' value={prop.civico} />
        </MDBox>
      </MDBox>

      <MDBox mb={2} >
        <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth disabled name='indirizzo2' value={prop.indirizzo2} />
      </MDBox>
      <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 8fr 1fr' }}>

        <MDBox>
          <MDInput type="text" label="CAP" fullWidth disabled name='cap' value={prop.cap} />
        </MDBox>
        <MDBox>
          <MDInput type="text" label="località" name='localita' fullWidth disabled value={prop.localita} />
        </MDBox>
        <MDBox>
          <MDInput type="text" label="provincia" name='provincia' fullWidth disabled value={prop.provincia} />
        </MDBox>
      </MDBox>

      <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
        <MDBox>
          <MDInput type="text" label="Codice Fiscale" name='cf' fullWidth disabled value={prop.cf} />
        </MDBox>
        <MDBox>
          <MDInput type="text" label="Partita IVA" name='piva' fullWidth disabled value={prop.piva} />
        </MDBox>
       <MDBox>
          <MDInput type="text" label="Telefono" name='telefono' fullWidth  disabled value={prop.telefono}/>
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Fax" name='fax' fullWidth disabled value={prop.fax?prop.fax:'/'}/>
                      </MDBox>

                      <MDBox>
                        <MDInput type="text" label="WWW" name='www' fullWidth  disabled value={prop.www?prop.www:'/'}/>
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Email" fullWidth  name='email' disabled value={prop.email} />
                      </MDBox>

                      <MDBox>
                        <MDInput type="text" label="PEC" name='pec' fullWidth  disabled value={prop.pec?prop.pec:'/'}          />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Codice SDI" fullWidth required name='codice_sdi'  disabled value={prop.codice_sdi?prop.codice_sdi:'/'} />
                      </MDBox>

                      <MDBox>
                        <MDInput type="text" label="Responsabile Fiera" name='responsabile_fiera' fullWidth  disabled value={prop.responsabile_fiera?prop.responsabile_fiera:'/'}
                        />
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Telefono Responsabile" fullWidth  name='telefono_resp' disabled value={prop.responsabile_fiera_telefono?prop.responsabile_fiera_telefono:'/'} />
                      </MDBox>

                      <MDBox>
                        <MDInput type="text" label="Cellulare" name='cell_resp' fullWidth  disabled value={prop.responsabile_fiera_cell?prop.responsabile_fiera_cell:'/'}  />
                        </MDBox>

                       <MDBox>
                        <MDInput type="text" label="Email (Da utilizzare per le comunicazioni dirette)" fullWidth  name='email_resp'  disabled value={prop.responsabile_fiera_email?prop.responsabile_fiera_email:'/'} />
                      </MDBox>
        
                 
                      <MDBox>
                        <MDInput type="text" label="IBAN (Per l'eventuale restituzione dell'anticipo versato)" fullWidth  name='iban'  disabled value={prop.iban?prop.iban:'/'} />
                      </MDBox>
                      <MDBox>
                      <MDInput type="text" label="CODICE MERCEOLOGIC0 1" fullWidth  disabled 
                      value={prop.cm_01?(prop.cm_01+"-"+prop.cm_01_descrizione):'/'}/>
                                                                                                   
                     </MDBox>
                     <MDBox>
                      <MDInput type="text" label="CODICE MERCEOLOGIC0 2" fullWidth  disabled 
                      value={
                        (prop.cm_02?(prop.cm_02+"-"+prop.cm_02_descrizione):'/')}/>
                                                                                                   
                     </MDBox>

                     <MDBox>
                      <MDInput type="text" label="CODICE MERCEOLOGIC0 3" fullWidth  disabled 
                      value={
                        prop.cm_03?(prop.cm_03+"-"+prop.cm_03_descrizione):'/'}/>
                                                                                                   
                     </MDBox>
                     <MDBox>
                      <MDInput type="text" label="CODICE MERCEOLOGIC0 4" fullWidth  disabled 
                      value={
                        prop.cm_04?(prop.cm_04+"-"+prop.cm_04_descrizione):'/'}/>
                                                                                                   
                     </MDBox>


                     </MDBox>
    

                     <MDBox>
                        <MDInput type="text" label="Prodotti / Servizi in esposizione (descrivere in maniera dettagliata)" fullWidth readOnly
                          name='prodotti_servizi' value={prop.prodotti_servizi} multiline rows={5} />
                     </MDBox>

                     <MDBox mt={2} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                     <MDBox>
                        <MDInput type="text" label="Note" fullWidth disabled
                          name='note' value={prop.note?prop.note:'/'} multiline rows={5} />
                     </MDBox>
                     <MDBox>
                        <MDInput type="text" label="Esigenze Espositive" fullWidth disabled
                          name='esigenze_espositive' value={prop.esigenze_espositive?prop.esigenze_espositive:'/'} multiline rows={5} />
                     </MDBox>
                      
                      </MDBox>


    </MDBox>
  );

}


ExpoRichiesteVis.defaultProps = {
  prop: null
};

ExpoRichiesteVis.propTypes = {
  prop: PropTypes.array
};

export default ExpoRichiesteVis;
