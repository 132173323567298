import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';


async function getAllComuni(cap){
    console.log("Filtro con cap="+cap  );
    let  capp=cap.replace('\r', "");
    return axios.get(axios.defaults.baseURL + `/localita/getAll/${capp}`);
}
const getAll=()=> {

  return axios.get(axios.defaults.baseURL + '/localita/all',{headers: authHeader()});
}

const create = (data) => {
    
  return axios.post(axios.defaults.baseURL + '/localita/ins',data, {
      headers: authHeader()
  })  
 };

 const update = (id, data) => {
      console.log("update= "+id);
      return axios.post(axios.defaults.baseURL + `/localita/update/${id}`, data,{headers: authHeader()});
    };

    const remove = (id) => {
      console.log("remove= "+id);
      return axios.post(axios.defaults.baseURL + `/localita/remove/${id}`,null,{headers: authHeader()});
    };


const ComuniService = {
    getAllComuni,
    getAll,
    create,update,remove
};

export default ComuniService;