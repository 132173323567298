import axios from "axios";



 const   login=(username, password)=>{
        console.log('AUTHService.js username=' +username);
        return axios.post(axios.defaults.baseURL+"/auth/signin",
        {
            username,
            password
          }).then(response => {
           console.log('AUTHService.js response');
            if (response.data.accessToken) {
              
                localStorage.setItem("user", JSON.stringify(response.data));
                console.log('AUTHService.js response'+response.data.roles);
            };
          //  console.log("role"+ JSON.parse(localStorage.getItem('user').roles));
            return response.data;
        });
    }

  const  getCurrentUser= ()=> {
    //   console.log('AUTHService.js getCurrentUser =' +localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem('user'));
      }

  const isLoggedIn= ()=>{
       return ( JSON.parse(localStorage.getItem('user'))!=null)? true:false; 

      }

const isExpo=()=>{
  const user= JSON.parse(localStorage.getItem('user'));
  if(user!=null){
  return  JSON.parse(localStorage.getItem('user')).roles.includes("ROLE_ESPOSITORE"); 
}else{
  return false;

 }
}

const isAdmin=()=>{
  const user= JSON.parse(localStorage.getItem('user'));
 if(user!=null){
  console.log(user.roles);
  const exists = user.roles.includes("ROLE_ADMIN");
  console.log("exists="+exists);
  return exists;
 }else{
  return false;

 }

}
  const logout = ()=> {
        localStorage.removeItem("user");
      }




const AuthService = {
    login,
    logout,
    isLoggedIn,
    getCurrentUser,
    isAdmin,isExpo
  };
  
export default AuthService;