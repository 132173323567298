import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

import AuthService from "services/API/Auth/AuthService";
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Alert from '@mui/material/Alert';
import { pink } from '@mui/material/colors';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";

//function
import currencyFormat from "common/script/currencyFormat";
import currencyToDouble from "common/script/currencyToDouble";
// Authentication layout components piu  largo

import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';
// Images

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { check } from "prettier";


function ExpoRichiesteEditStep2() {

  const color_pink = pink[300];


  const location = useLocation();
  const navigate = useNavigate();


  // user state for form
  const [prop, setProp] = useState({
    idRichiesta: 0,
    id_utente: AuthService.getCurrentUser().id,
    manifestazione: '',
    ragione_sociale: '',
    rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
    responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
    prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
    tipologia_espositiva_scelta: '', note: '',
    q_10: 0, q_11: 0, q_12: 0, q_13: 0, importo_area: 0, imponibile: 0, iva: 0, totale: 0, anticipo_versato: 0, aliquota: 0.22,
  });


  const { modo, soggetto, subject } = location.state;
  
  /* ----------------------------FUNZIONALITA PAGINA------------------------------------------------- */
/* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */
const [field_aliquota, setField_aliquota] = useState(0.22);
const [anticipo_dovuto, setAnticipo_dovuto] = useState("0,00");


const validationSchema = Yup.object().shape({
  note: Yup.string().when("$ali", {
    is:(val) => {
      return field_aliquota === 0;
  },
    then: (s) => s.required('Specificare le motivazioni dell\'iva non dovuta nel campo annotazioni'),
    otherwise: (s) => s,
}),
  
});

  const {
    register, //register inputs
    watch, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });



  const myCheckBox = ["check_01", "check_02", "check_03", "check_04", "check_05", "check_06", "check_07", "check_08", "check_09", "check_10", "check_11", "check_12", "check_13"];
  const myTot = ["01_tot", "02_tot", "03_tot", "04_tot", "05_tot", "06_tot", "07_tot", "08_tot", "09_tot", "10_tot", "11_tot", "12_tot", "13_tot"];
  const myQuantity = ["1", "1", "1", "1", "1", "1", "1", "1", "1", "q_10", "q_11", "q_12", "q_13"];
  const myPu = [1150, 1200, 2400, 3500, 1400, 2300, 2550, 3400, 4000, 75, 80, 1800, 1000];



  const onChangeQ = (event) => {

    //console.log(event.target.name + " = " + event.target.value);
    let index = myQuantity.indexOf(event.target.name);
    let tot_riga = event.target.value * myPu.at(index);

    setValue(myTot.at(index), currencyFormat(tot_riga));
    // setValue("importo_area", event.target.value * myPu.at(index));
    setValue("imponibile", currencyFormat(tot_riga));

    let iva = tot_riga * field_aliquota;
    setValue("iva", currencyFormat(iva));
    let totale = tot_riga + iva;
    setValue("totale", currencyFormat(totale));
    let anticipo = totale * 0.30;
   
    setAnticipo_dovuto( currencyFormat(anticipo));
  }




  const puntoToVirgola = (event) => {

    let val = event.target.value.replaceAll(".", ",");
    setValue(event.target.name, val);
  }

  const handleChangeAliquota = (event) => {
    setField_aliquota(event.target.value);
    console.log("VAL=" + field_aliquota);
}


  const selectRow = (event) => {

    console.log(event.target.name);

    if (event.target.checked) {

      myCheckBox.forEach((item, index, array) => {
        if (item === event.target.name) {
          let q = myQuantity.at(index);
          if (index > 8) {          
        
            setValue(myQuantity.at(index), 1);
            q = 1;
          }

          setValue(myTot.at(index), currencyFormat(myPu.at(index) * q));

          // setValue("importo_area", myPu.at(index) * q);
          let imponibile = myPu.at(index) * q;
          setValue("imponibile", currencyFormat(imponibile));
          let iva = (myPu.at(index) * q) * field_aliquota;
          setValue("iva", currencyFormat(iva));
          let totale = imponibile + iva;
          setValue("totale", currencyFormat(totale));
          let anticipo = totale * 0.30;
          setAnticipo_dovuto( currencyFormat(anticipo));
        } else {

          setValue(item, false);
          setValue(myTot.at(index), "0,00");
          setValue(myQuantity.at(index), 0);
       


        }
      });
 





    } else {
      let index = myCheckBox.indexOf(event.target.name);
      setValue(myTot.at(index), "0,00");
      if (index > 8) {
        setValue(myQuantity.at(index), 0);

      }
      // setValue("importo_area", 0);
      setValue("imponibile", "0,00");
      setValue("iva", "0,00");
      setValue("totale", "0,00");

      setAnticipo_dovuto(0.00);

    }


  }


  const field_imponibile = currencyToDouble(watch("imponibile"));






  /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */

  useEffect(() => {
    if (soggetto != null) {
      console.log("soggetto non nullo: id=" + soggetto);
    //  console.log(soggetto);
      seleziona_soggetto();
    } else {
      console.log("soggetto nullo");
    }
  }, [soggetto]);

  async function seleziona_soggetto() {
    console.log("Cambiata pp->seleziono i dettagli :" + soggetto);
    if (soggetto !== 0) {
      const response = await ERService.seleziona(soggetto);
      response.errors ? console.log(response.errors) : retrive(response.data);

    }
  }

  function retrive(data) {
    console.log(data);
    console.log("retrive =" + data.idRichiesta);

    setProp({
      idRichiesta: data.idRichiesta,
      id_utente: data.id_utente,
      manifestazione: data.manifestazione,
      ragione_sociale: data.ragione_sociale,
      rappresentante_legale: data.rappresentante_legale,
      indirizzo: data.indirizzo,
      civico: data.civico,
      cap: data.cap,
      localita: data.localita,
      provincia: data.provincia,
      indirizzo2: data.indirizzo2,
      cf: data.cf,
      piva: data.piva,
      telefono: data.telefono,
      fax: data.fax,
      email: data.email,
      www: data.www,
      pec: data.pec,
      iban: data.iban,
      codice_sdi: data.codice_sdi,
      cm_01: data.cm_01,
      cm_02: data.cm_02,
      cm_03: data.cm_03,
      cm_04: data.cm_04,
      responsabile_fiera: data.responsabile_fiera,
      responsabile_fiera_telefono: data.responsabile_fiera_telefono,
      responsabile_fiera_cell: data.responsabile_fiera_cell,
      responsabile_fiera_email: data.responsabile_fiera_email,
      prodotti_servizi: data.prodotti_servizi,
      produttore: data.produttore,
      concessionario: data.concessionario,
      distributore: data.distributore,
      rivenditore: data.rivenditore,
      importatore: data.importatore,
      tipologia_espositiva_scelta: data.tipologia_espositiva_scelta,
      q_10: data.q_10, q_11: data.q_11, q_12: data.q_12, q_13: data.q_13,
      aliquota:data.aliquota,
      importo_area: data.importo_area, imponibile: data.imponibile, iva: data.iva,
      totale: data.totale, anticipo_versato: data.anticipo_versato,
      note: data.note, esigenze_espositive: data.esigenze_espositive,
    });

    console.log("selezionata  " + prop.idRichiesta);

  }



  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + prop.idRichiesta);


    if (prop.tipologia_espositiva_scelta === '01') {
      setValue("check_01", true);
      setValue("01_tot", currencyFormat(myPu.at(0)));
    }
    if (prop.tipologia_espositiva_scelta === '02') {
      setValue("check_02", true);
      setValue("02_tot", currencyFormat(myPu.at(1)));
    }
    if (prop.tipologia_espositiva_scelta === '03') {
      setValue("check_03", true);
      setValue("03_tot", currencyFormat(myPu.at(2)));
    }
    if (prop.tipologia_espositiva_scelta === '04') {
      setValue("check_04", true);
      setValue("04_tot", currencyFormat(myPu.at(3)));
    }
    if (prop.tipologia_espositiva_scelta === '05') {
      setValue("check_05", true);
      setValue("05_tot", currencyFormat(myPu.at(4)));
    }

    if (prop.tipologia_espositiva_scelta === '06') {
      setValue("check_06", true);
      setValue("06_tot", currencyFormat(myPu.at(5)));
    }
    if (prop.tipologia_espositiva_scelta === '07') {
      setValue("check_07", true);
      setValue("07_tot", currencyFormat(myPu.at(6)));
    }
    if (prop.tipologia_espositiva_scelta === '08') {
      setValue("check_08", true);
      setValue("08_tot", currencyFormat(myPu.at(7)));
    }

    if (prop.tipologia_espositiva_scelta === '09') {
      setValue("check_09", true);
      setValue("09_tot", currencyFormat(myPu.at(8)));
    }
    if (prop.tipologia_espositiva_scelta === '10') {
      setValue("check_10", true);
      setValue("q_10", prop.q_10);
      setValue("10_tot", currencyFormat(myPu.at(9) * prop.q_10));
    }


    if (prop.tipologia_espositiva_scelta === '11') {
      setValue("check_11", prop.tipologia_espositiva_scelta === '11');
      setValue("q_11", prop.q_11);
      setValue("11_tot", currencyFormat(myPu.at(10) * prop.q_11));
    }
    if (prop.tipologia_espositiva_scelta === '12') {
      setValue("check_12", prop.tipologia_espositiva_scelta === '12');
      setValue("q_12", prop.q_12);
      setValue("12_tot", currencyFormat(myPu.at(11) * prop.q_12));
    }
    if (prop.tipologia_espositiva_scelta === '13') {
      setValue("check_13", prop.tipologia_espositiva_scelta === '13');
      setValue("q_13", prop.q_13);
      setValue("13_tot", currencyFormat(myPu.at(12) * prop.q_13));
    }

    setField_aliquota(prop.aliquota);



    setValue("note", prop.note);
    setValue("esigenze_espositive", prop.esigenze_espositive);

    // setValue("importo_area", prop.importo_area);
    setValue("imponibile", currencyFormat(prop.imponibile));
    setValue("iva", currencyFormat(prop.iva));
    setValue("totale", currencyFormat(prop.totale));

    setAnticipo_dovuto(currencyFormat(prop.totale*0.3));
    setValue("anticipo_versato", currencyFormat(prop.anticipo_versato));



  }, [prop]);


  


  /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */


  const [submitted, setSubmitted] = useState(false);


  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {

    console.log("CAMBIO ALIQUOTA + " + field_aliquota);  
   if (field_aliquota == 0) {
      setValue("iva", "0,00");
      setValue("totale", currencyFormat(field_imponibile));
      let anticipo = field_imponibile * 0.30;
      setValue("anticipo_dovuto", currencyFormat(anticipo));


    } else {

      let iva = field_imponibile * field_aliquota;
      setValue("iva", currencyFormat(iva));
      let totale = field_imponibile + iva;
      setValue("totale", currencyFormat(totale));
      let anticipo = totale * 0.30;
      setValue("anticipo_dovuto", currencyFormat(anticipo));



    }

  }, [field_aliquota]);



  const inserisci_pp = (formData) => {

    setError(null);

    let manifestazione = subject;


    let opzione_scelta = '';

    if (formData.check_01) {
      opzione_scelta = "01";
    }
    if (formData.check_02) {
      opzione_scelta = "02";
    }
    if (formData.check_03) {
      opzione_scelta = "03";
    }
    if (formData.check_04) {
      opzione_scelta = "04";
    }
    if (formData.check_05) {
      opzione_scelta = "05";
    }
    if (formData.check_06) {
      opzione_scelta = "06";
    }
    if (formData.check_07) {
      opzione_scelta = "07";
    }
    if (formData.check_08) {
      opzione_scelta = "08";
    }
    if (formData.check_09) {
      opzione_scelta = "09";
    }
    if (formData.check_10) {
      opzione_scelta = "10";
    }
    if (formData.check_11) {
      opzione_scelta = "11";
    }
    if (formData.check_12) {
      opzione_scelta = "12";
    } if (formData.check_13) {
      opzione_scelta = "13";
    }

    let imponibile = currencyToDouble(formData.imponibile);
    let iva = currencyToDouble(formData.iva);
    let totale = currencyToDouble(formData.totale);
    let anticipo_versato = currencyToDouble(formData.anticipo_versato);
    //console.log("imponibile="+imponibile);
    //console.log("iva="+iva);
    //console.log("totale="+totale);
    //console.log("anticipo_versato="+anticipo_versato);



    var data = {
      idRichiesta: prop.idRichiesta,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: prop.manifestazione,
      ragione_sociale: prop.ragione_sociale,
      rappresentante_legale: prop.rappresentante_legale,
      indirizzo: prop.indirizzo,
      civico: prop.civico,
      cap: prop.cap,
      localita: prop.localita,
      indirizzo2: prop.indirizzo2,
      provincia: prop.provincia,
      piva: prop.piva,
      cf: prop.cf,
      telefono: prop.telefono,
      fax: prop.fax,
      www: prop.www,
      email: prop.email,
      pec: prop.pec,
      iban: prop.iban,
      codice_sdi: prop.codice_sdi,
      cm_01: prop.cm_01, cm_02: prop.cm_02, cm_03: prop.cm_03, cm_04: prop.cm_04,
      responsabile_fiera: prop.responsabile_fiera,
      responsabile_fiera_telefono: prop.responsabile_fiera_telefono,
      responsabile_fiera_cell: prop.responsabile_fiera_cell,
      responsabile_fiera_email: prop.responsabile_fiera_email,
      prodotti_servizi: prop.prodotti_servizi,
      produttore: prop.produttore, concessionario: prop.concessionario, distributore: prop.distributore,
      rivenditore: prop.rivenditore, importatore: prop.importatore,

      tipologia_espositiva_scelta: opzione_scelta,
      q_10: formData.q_10, q_11: formData.q_11, q_12: formData.q_12, q_13: formData.q_13,
      aliquota:field_aliquota,
      importo_area: imponibile,
      imponibile: imponibile,
      iva: iva, totale: totale, anticipo_versato: anticipo_versato,
      note: formData.note, esigenze_espositive: formData.esigenze_espositive,

    };

    if (modo === 'edit') {
      ERService.update(prop.idRichiesta, data)
        .then(response => {
          handleResponse(response);
        })
        .catch(errore => {
          handleError(errore);
        });
    }


  }

  function handleResponse(response) {

    console.log(response.data);
    setSubmitted(true);
    console.log(response.data);
    navigate("/expo_richiestaConfirm", { state: { modo: 'edit', soggetto: prop, subject: null } });
  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }


  /* ---------------- ALERT -------------------------------------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);



  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setConfirm(true);
    setOpen(false);
    deleteAnag();
  }


  const handleOpen = () => {
    console.log("OPEN DIALOG");
    setOpen(true);
  }

  function handleCloseExistent() {
    setOpenExistent(false);
  }

  function handleConfirmExistent() {
    setConfirmExistent(true);
    setOpenExistent(false);

  }

  const deleteAnag = () => {
    setError(null);

    if (modo === 'edit') {

      console.log("delete anag");

      ERService.remove(prop.idRichiesta)
        .then(response => {
          console.log("handleResponse");
          handleResponse(response, 'DEL');
        })
        .catch(errore => {
          console.log("handleError");
          handleError(errore);
        });

    }
  };


  /* ------------------------------navigazione -----------------------------------------------------------------*/


  const annulla = () => {


    navigate("/expo_richieste");
  }

  const handleKeyDown = (e) => {
    const { key, target } = e

    if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
      return
    }

    e.preventDefault()
  }



  return (
    <DashboardLayout>

      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
            Eliminare questo record?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sei sicuro di voler eliminare questa richiesta di partecipazione?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>No</MDButton>
            <MDButton onClick={handleConfirm} autoFocus>
              SI
            </MDButton>
          </DialogActions>
        </Dialog>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card >
              <HeaderForm titolo={"Proposta di Partecipazione " + subject} save={handleSubmit(inserisci_pp)} remove={handleOpen} exit={annulla} modo={modo} />
              {error !== null &&
                <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
              }


              <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>


                <CardContent>
                  <MDBox>
                    <MDBox opacity={1} textAlign="center">

                    </MDBox>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" >

                      {(submitted) &&

                        <Alert severity="success">Dati salvati con successo!</Alert>}
                      {error !== null &&
                        <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                      }
                    </MDBox>

                    <MDBox pt={4} pb={3} px={3} >
                      <MDBox>
                        <ExpoRichiesteStepper index_attivo={2} />
                      </MDBox>

                      <MDBox mb={2} sx={{ width: '100%' }}>

                        <MDBox mb={4}>

                          <MDButton variant="gradient" color="success" fullWidth>
                            <MDTypography variant="h5" fontWeight="regular" sx={{
                              color: "#FFFFFF"
                            }}>TIPOLOGIE ESPOSITIVE E TARIFFE</MDTypography>
                          </MDButton>
                        </MDBox>


                        {/*############################################################################################################################################### */}


                        <MDBox>

                          <MDBox mb={2} mt={4} >
                            <Grid container spacing={2} mb={2} columns={13} sx={{
                              fontSize: 12,
                              boxShadow: 2,
                              border: 2,
                              borderColor: 'info.light',
                              '& .MuiDataGrid-cell:hover': {
                                color: '#e91e63',
                              },
                            }}>
                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>STAND AL COPERTO</MDTypography>
                              </Grid>


                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMNISTRAZIONE</MDTypography>

                              </Grid>

                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="caption" fontWeight="regular" >
                                  Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

                              </Grid>


                              <Grid item sm={2} md={2} lg={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO AREA</MDTypography>
                              </Grid>


                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                  SETTORI AMMESSI</MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO DI STAND</MDTypography>
                              </Grid>

                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  PREZZO UNITARIO</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TOT</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_01" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                      STAND AL COPERTO
                                    </MDTypography>
                                  </MDBox>}
                                />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  01 - VENDITA PRODOTTI AGROALIMENTARI</MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} >
                                <Grid container spacing={2} >
                                  <Grid item xs={4} md={4} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      2 LATI APERTI</MDTypography></Grid>
                                  <Grid item xs={8} md={8} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      12 mq (mt 4x3) - Pad. 7</MDTypography>
                                    {false && <MDInput name='01_q' value="1" />}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 1.150,00

                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" name='01_tot' {...register('01_tot')} variant="standard" 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled justify="flex-end"
                                  /><MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_02" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  01 - VENDITA PRODOTTI AGROALIMENTARI</MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} >
                                <Grid container spacing={2} >
                                  <Grid item xs={4} md={4} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={8} md={8} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      16 mq (mt 4x4) - Nuova Area Nord</MDTypography></Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 1.200,00
                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" name='02_tot' variant="standard" {...register('02_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_03" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  02 - SOMMINISTRAZIONE PRODOTTI AGROALIMENTARI</MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} >
                                <Grid container spacing={2} >
                                  <Grid item xs={4} md={4} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={8} md={8} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      16 mq (mt 4x4) - Nuova Area Nord</MDTypography></Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 2.400,00

                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" name='03_tot' variant="standard" {...register('03_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>

                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_04" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  02 - SOMMINISTRAZIONE PRODOTTI AGROALIMENTARI</MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} >
                                <Grid container spacing={2} >
                                  <Grid item xs={4} md={4} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={8} md={8} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      32 mq (mt 4x8) - Nuova Area Nord
                                    </MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 3.500,00
                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" variant="standard" name='04_tot' {...register('04_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>

                              {/*---------------------------------------------------------------------------------------------*/}



                            </Grid>

                          </MDBox>

                        </MDBox>



                        {/*############################################################################################################################################### */}


                        <MDBox>

                          <MDBox mb={2} mt={4} >
                            <Grid container spacing={2} mb={2} columns={13} sx={{
                              fontSize: 12,
                              boxShadow: 2,
                              border: 2,
                              borderColor: '#e91e63',
                              '& .MuiDataGrid-cell:hover': {
                                color: '#e91e63',
                              },
                            }}>
                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>STAND AL COPERTO</MDTypography>

                              </Grid>


                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                              </Grid>

                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="caption" fontWeight="regular" >
                                  Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                </MDTypography>

                              </Grid>


                              <Grid item sm={2} md={2} lg={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO AREA</MDTypography>
                              </Grid>


                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                  SETTORI AMMESSI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO DI STAND</MDTypography>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  OLTRE MQ</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  PREZZO UNITARIO</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TOT</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_05" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      16 mq (mt 4x4)</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >

                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 1.400,00

                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" variant="standard" name='05_tot' {...register('05_tot')}
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_06" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      24 mq (mt 4x6)</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >

                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 2.300,00
                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" variant="standard" name='06_tot' {...register('06_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_07" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      1 LATO APERTO</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      32 mq (mt 4x8)</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >

                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 2.550,00
                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" variant="standard" name='07_tot' {...register('07_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>

                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_08" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      2 LATI APERTI</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      48 mq (mt 12x4)</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >

                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 3.400,00 </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" variant="standard" size="small" name='08_tot' {...register('08_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>

                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_09" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />

                                  
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      3 LATI APERTI</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      48 mq (mt 6x8)</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >

                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  € 4.000,00 </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" size="small" name='09_tot' {...register('09_tot')} variant="standard" 
                                inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>


                              {/*---------------------------------------------------------------------------------------------*/}

                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_10" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      STAND AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      3 LATI APERTI</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      64 mq E OLTRE MQ</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDInput type="text" label="" name='q_10' 
                                   disabled={!watch("check_10")}
                                {...register('q_10', { onChange: (e) => { onChangeQ(e) } })} size="small" />
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  x 75€ </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" label="" variant="standard" size="small" name='10_tot' {...register('10_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>


                              {/*---------------------------------------------------------------------------------------------*/}

                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_11" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                      STAND AL COPERTO
                                    </MDTypography>
                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      4 LATI APERTI</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      64 mq E OLTRE MQ</MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDInput type="text" size="small" label="" name='q_11'
                                   disabled={!watch("check_11")}
                                {...register('q_11', { onChange: (e) => { onChangeQ(e) } })} />
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  x 80€ </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput size="small" variant="standard" type="text" label="" name='11_tot' {...register('11_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>


                              {/*---------------------------------------------------------------------------------------------*/}


                            </Grid>

                          </MDBox>

                        </MDBox>

                        {/*############################################################################################################################################### */}


                        <MDBox>

                          <MDBox mb={2} mt={4} >
                            <Grid container spacing={2} mb={2} columns={13} sx={{
                              fontSize: 12,
                              boxShadow: 2,
                              border: 2,
                              borderColor: '#ff9800',
                              '& .MuiDataGrid-cell:hover': {
                                color: '#ff9800',
                              },
                            }}>
                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>AREA AL COPERTO</MDTypography>

                              </Grid>


                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>SETTORI MERCEOLOGICI AMMESSI: 11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>

                              </Grid>

                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="caption" fontWeight="regular" >
                                  La tariffa comprende: area espositiva,estintore, servizio antincendio, energia elettrica pari a 2kw/220 V ogni 48 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                </MDTypography>

                              </Grid>


                              <Grid item sm={2} md={2} lg={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO AREA</MDTypography>
                              </Grid>


                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                  SETTORI AMMESSI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO DI STAND</MDTypography>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  N°</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  PREZZO UNITARIO</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TOT</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_12" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                      AREA AL COPERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      Modulo mq 48</MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                    </MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDInput type="text" size="small" label="" name='q_12' 
                                   disabled={!watch("check_12")}
                                {...register('q_12', { onChange: (e) => { onChangeQ(e) } })} />
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  x  1.800,00 €

                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" variant="standard" label="" size="small" name='12_tot' {...register('12_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}


                            </Grid>

                          </MDBox>

                        </MDBox>

                        {/*############################################################################################################################################### */}


                        <MDBox>

                          <MDBox mb={2} mt={4} >
                            <Grid container spacing={2} mb={2} columns={13} sx={{
                              fontSize: 12,
                              boxShadow: 2,
                              border: 2,
                              borderColor: '#ff9800',
                              '& .MuiDataGrid-cell:hover': {
                                color: '#ff9800',
                              },
                            }}>
                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>AREA ALL'APERTO</MDTypography>

                              </Grid>


                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>SETTORI MERCEOLOGICI AMMESSI: 11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>

                              </Grid>

                              <Grid item sm={13} md={13} lg={13} >
                                <MDTypography variant="caption" fontWeight="regular" >
                                  La tariffa comprende: area espositiva, servizio antincendio, energia elettrica pari a 1kw/220 V ogni 50 mq, inserimento a catalogo, inviti, pass espositori, pass auto.    </MDTypography>

                              </Grid>


                              <Grid item sm={2} md={2} lg={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO AREA</MDTypography>
                              </Grid>


                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                  SETTORI AMMESSI</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TIPO DI STAND</MDTypography>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  N°</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  PREZZO UNITARIO</MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="medium" >
                                  TOT</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}
                              <Grid item xs={2} md={2} >
                                <ReactHookFormCheckBox
                                  name="check_13" control={control} onChange={selectRow}
                                  label={<MDBox>
                                    <MDTypography variant="overline" fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                      AREA ALL'APERTO
                                    </MDTypography>

                                  </MDBox>} />
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>
                              </Grid>

                              <Grid item xs={3} md={3} >
                                <Grid container spacing={2} >
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                      Modulo mq 50
                                    </MDTypography></Grid>
                                  <Grid item xs={6} md={6} >
                                    <MDTypography variant="overline" fontWeight="regular" >
                                    </MDTypography></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} md={1} >
                                <MDInput type="text" size="small" label="" name='q_13' 
                     disabled={!watch("check_13")}
                                {...register('q_13', { onChange: (e) => { onChangeQ(e) } })} />
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDTypography variant="overline" fontWeight="regular" >
                                  x  1.000,00 €

                                </MDTypography>
                              </Grid>
                              <Grid item xs={2} md={2} >
                                <MDInput type="text" variant="standard" label="" size="small" name='13_tot' {...register('13_tot')} 
                                 inputProps={{style: { textAlign: "right"}}} readonly={true} disabled />
                                <MDTypography variant="button" fontWeight="overline" >€</MDTypography>
                              </Grid>
                              {/*---------------------------------------------------------------------------------------------*/}


                            </Grid>

                          </MDBox>

                        </MDBox>

                        {/*############################################################################################################################################### */}
                      </MDBox>


                      <MDBox>
                        <Grid container spacing={2} mb={2} >
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" ></MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" ></MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" ></MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" ></MDTypography>
                          </Grid>

                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" >IMPONIBILE</MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDInput type="text"  inputProps={{style: { textAlign: "right"}}} variant="standard" label="" size="small" name='imponibile' {...register('imponibile')} readonly={true} disabled />
                            <MDTypography variant="button" fontWeight="regular" > €</MDTypography>
                          </Grid>

                          <Grid item sm={8} md={8} lg={8} >
                          <FormControl fullWidth>
                  <MDTypography variant="button" htmlFor="aliquota" shrink={true} >Aliquota IVA</MDTypography>
                  <NativeSelect id="aliquota"
                              name="aliquota"
                              label="Aliquota Iva"
                              value={field_aliquota}
                              onChange={handleChangeAliquota}       
                              defaultValue='0.22'
                              variant="outlined"
                              required
                              error={errors.aliquota ? true : false}
                            >
                               <option 
                                key='22'
                                value='0.22'
                              >
                                22%
                              </option>
                              <option
                                key='0'
                                value='0'

                              >
                               IVA Non dovuta (Specificare la motivazione nelle annotazioni)
                              </option>
                            </NativeSelect></FormControl>
                          </Grid>

                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" >IVA</MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDInput type="text" variant="standard"  inputProps={{style: { textAlign: "right"}}} label="" size="small" name='iva' {...register('iva')} readonly={true} disabled />
                            <MDTypography variant="button" fontWeight="regular" > €</MDTypography>
                          </Grid>

                          <Grid item sm={8} md={8} lg={8} >
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" >TOTALE</MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDInput type="text" variant="standard"  inputProps={{style: { textAlign: "right"}}} label="" size="small" name='totale' {...register('totale')} readonly={true} disabled />
                            <MDTypography variant="button" fontWeight="regular" > €</MDTypography>
                          </Grid>

                           
                          <Grid item sm={8} md={8} lg={8} >
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" >ANTICIPO DOVUTO</MDTypography>
                          </Grid>
                          <Grid item sm={2} md={2} lg={2} >
                          <MDInput type="text"  inputProps={{style: { textAlign: "right"}}} variant="standard"
                           label="" size="small" name='anticipo_dovuto' value={anticipo_dovuto} readonly={true} disabled />
                      
                            <MDTypography variant="button" fontWeight="regular" > €</MDTypography>
                          </Grid>         

                        </Grid>
                      </MDBox>


                      <MDBox >
                        <MDTypography variant="button" fontWeight="regular" > Come previsto dal regolamento, alla sottoscrizione della presente è richiesto un anticipo pari al 30% dell'importo totale.
                          Il versamento dell'anticipo potrà essere evaso tramite BONIFICO BANCARIO su :
                        </MDTypography>
                      </MDBox><MDBox>
                        <MDTypography variant="button" fontWeight="medium" >
                          BANCA MONTE DEI PASCHI DI SIENA - Agenzia Bastia Umbra</MDTypography>
                      </MDBox><MDBox>
                        <MDTypography variant="button" fontWeight="medium" >
                          IBAN IT 84 U 01030 38280 000063133536 - Codice BIC PASCITMMBAS </MDTypography>
                      </MDBox><MDBox>
                        <MDTypography variant="button" fontWeight="medium" >
                          intestato a Umbriafiere S.p.a.
                        </MDTypography>


                      </MDBox>

                      <MDBox>
                        <Grid container spacing={2} mb={2} >
                          <Grid item sm={2} md={2} lg={2} >
                            <MDTypography variant="button" fontWeight="regular" >ANTICIPO VERSATO</MDTypography>
                          </Grid>
                          <Grid item sm={3} md={3} lg={3} >
                            <MDInput type="text" variant="standard" label="" size="small" name='anticipo_versato'
                              {...register('anticipo_versato', { onChange: (e) => { puntoToVirgola(e) } })} />
                            <MDTypography variant="button" fontWeight="regular" > €</MDTypography>
                          </Grid>
                          <Grid item sm={7} md={7} lg={7} ></Grid>

                          <Grid item sm={12} md={6} lg={6} >
                            <MDInput type="text" label="Esigenze espositive" multiline rows={5} fullWidth variant="outlined" InputLabelProps={{ shrink: true }}
                              name='esigenze_espositive' {...register('esigenze_espositive')} /></Grid>


                          <Grid item sm={12} md={6} lg={6} >
                            <MDInput type="text" label="Annotazioni" multiline rows={5} fullWidth variant="outlined" InputLabelProps={{ shrink: true }}
                              name='note' {...register('note')}   
                              error={errors.note ? true : false} />
                        {errors.note ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.note?.message}</span> : ''}
                           </Grid>
                        </Grid>

                      </MDBox>





                    </MDBox>{/*fine seleziona area*/}


                    <MDBox mb={2}>
                    </MDBox>



                    <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                      <MDButton variant="outlined" color="success" onClick={annulla} >
                        annulla
                      </MDButton>
                      <MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}>

                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="gradient" color="success" onClick={handleSubmit(inserisci_pp)} type="submit"  >
                          avanti
                        </MDButton>
                      </MDBox>

                    </MDBox>

                    <MDBox>

                    </MDBox>


                  </MDBox>
                </CardContent>

              </MDBox>

            </Card>
          </Grid >
        </Grid >
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

export default ExpoRichiesteEditStep2;
