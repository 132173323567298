import { useState,useEffect } from "react";
import { forwardRef } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";

// Material Dashboard 2 React example components
import PageLayout from "common/components/implemented/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "layouts/components/Footer2";


const SignupLayout = forwardRef(function SignupLayout({ image, children}, myRef) {


  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto" >
        <MDBox  sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          height: "90%",
         
         // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
          <div ref={myRef} style={{overflow: "scroll",
          overflowY: "scroll",}} >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="90%"  >
        
          <Grid item xs={11} sm={11} md={10} lg={10} xl={10} >
          
            {children}
          
          </Grid>
        
        </Grid>
        </div>
        </MDBox>
      </MDBox>
      <Footer light />
    </PageLayout>
  );


});

  // Typechecking props for the BasicLayout
  SignupLayout.propTypes = {
    image: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  };


export default SignupLayout;
