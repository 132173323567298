import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
// Authentication layout components piu largo
import SignupLayout from "layouts/SignupLayout";

// react-router-dom components
import { Link } from "react-router-dom";
// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import UtentiService from "services/API/Utenti/UtentiService";

function RequestChangePassword() {

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const validationSchema = Yup.object().shape({

        email: Yup.string()
            .required('Email richiesta')
            .email('Email non valida'),
    });

    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const richiedi_reset_pwd = (formData) => {

        setError(null);

        var data={
            email: formData.email};

        console.log("request reset");
        UtentiService.request_reset_pwd(data)
            .then(response => {
                handleResponse(response);
            })
            .catch(errore => {
                handleError(errore);


            });
      
    };

    function handleResponse(response) {

        console.log(response.data);
        setSubmitted(true);
        reset({
            email: '',
        });

    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("EEROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }



    return (
        <SignupLayout image={bgImage}  >
            <Card sx={{ mt: 10, mb: 15 }} >
                <MDBox
                    variant="gradient"
                    bgColor="success"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >

                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        FARFIERA
                    </MDTypography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                    </Grid>
                </MDBox>

                <MDBox pt={1} pb={3} px={3} component="form" role="form">


                    <CardContent>
                        <MDBox>
                            <MDBox opacity={1} textAlign="center">
                                <MDTypography variant="h5" fontWeight="medium" sx={{
                                    color: "#388e3c"
                                }} mt={1} mb={2} >
                                    Reset Password azienda
                                </MDTypography>
                            </MDBox>
                            {((submitted) || (error !== null)) &&
                                <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" mb={4}>

                                    {(submitted) &&
                                        <MDBox textAlign="center">
                                            <Alert severity="success">Richiesta inserita con successo! Ti sarà inviata una email per resettare la password!</Alert>
                                        </MDBox>
                                    }
                                    {error !== null &&
                                        <MDBox textAlign="center">
                                            <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                        </MDBox>
                                    }
                                </MDBox>
                            }
                            {(!submitted) &&
                                <MDBox>
                                    <MDBox opacity={1} textAlign="center" mb={4}>
                                        <MDTypography variant="button" sx={{
                                        }} mt={1}  >
                                            Il seguente modulo ti permetterà di resettare la password per entrare nell'Area Riservata e poter compilare la Proposta di Partecipazione
                                        </MDTypography>
                                    </MDBox>






                                    <MDBox mb={2} >
                                        <MDInput type="text" label="Email(Username)" fullWidth required name='email' {...register('email')} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                                        {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
                                    </MDBox>




                                </MDBox>
                            }

                        </MDBox>
                    </CardContent>
                    {(!submitted) &&
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit(richiedi_reset_pwd)} type="submit">
                                Richiedi reset password
                            </MDButton>
                        </MDBox>}
                    <MDBox mt={3} mb={1} textAlign="center">
                        <Link to={`/login`}>
                            <MDTypography

                                variant="button"
                                color="success"
                                fontWeight="medium"
                                textGradient
                            >
                                Torna al login
                            </MDTypography></Link>
                    </MDBox>

                </MDBox>

            </Card>
        </SignupLayout>
    );


}
export default RequestChangePassword;
