import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";


import DataTable from "common/components/implemented/Tables/DataTable/DataTables";


// Data
import VisitatoriDataService from "services/API/Anagrafica/AnagraficaService";
function VisitatoriBox() {

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [visitatori, setVisitatori] = useState([]);
 
  useEffect(() => {

    retrieveVisitatori();

  }, []);
  const retrieveVisitatori = () => {
    VisitatoriDataService.getLastVisitatori()
      .then(response => {
        setVisitatori(response.data);
       console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };

  const visitatoreAttributes = []
  visitatori.forEach(visitatore => {
    visitatoreAttributes.push({
      cognome: visitatore.cognome,
       nome: visitatore.nome,
      azienda:visitatore.azienda,
      email: visitatore.email,
      provenienza: visitatore.comune+'('+visitatore.provincia+')',
    })
  });
  const visitatoriTableData = {
    columns: [
      { Header: "Cognome", accessor: "cognome", width: "15%", align: "left" },
      { Header: "Nome", accessor: "nome", width: "15%", align: "left" },
      { Header: "Azienda", accessor: "azienda", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Provenienza", accessor: "provenienza", align: "left" },
    ],

    rows: visitatoreAttributes,


  };
  


  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Ultimi Visitatori Registrati
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
    {/*renderMenu*/}
      </MDBox>
      <MDBox>
             <DataTable
                  table={visitatoriTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />     
      </MDBox>
    </Card>
  );
}

export default VisitatoriBox;
