import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import AuthService from "services/API/Auth/AuthService";
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper";

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

//script
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import currencyFormat from "common/script/currencyFormat"

const ExpoRichiestaConfirm = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, subject } = location.state;

    // user state for form
    const [prop, setProp] = useState({
        idRichiesta: 0,
        id_utente: AuthService.getCurrentUser().id,
        manifestazione: subject,
        ragione_sociale: '',
        rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
        responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
        prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
        tipologia_espositiva_scelta: '', note: '',
        q_10: 0, q_11: 0, q_12: 0, q_13: 0, importo_area: 0, imponibile: 400, iva: 400 * 0.22, totale: 488, anticipo_versato: 120
    });



    /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */

    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idRichiesta);
            seleziona_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function seleziona_soggetto() {
        console.log("Cambiata pp->seleziono i dettagli :" + soggetto.idRichiesta);
        if (soggetto.idRichiesta !== 0) {
            const response = await ERService.seleziona(soggetto.idRichiesta);
            response.errors ? console.log(response.errors) : retrive(response.data);
        }
    }

     
    function retrive(data) {
  
        setProp({
            idRichiesta: data.idRichiesta,
            id_utente: data.id_utente,
            manifestazione: data.manifestazione,
            ragione_sociale: data.ragione_sociale,
            rappresentante_legale: data.rappresentante_legale,
            indirizzo: data.indirizzo,
            civico: data.civico,
            cap: data.cap,
            localita: data.localita,
            provincia: data.provincia,
            indirizzo2: data.indirizzo2,
            cf: data.cf,
            piva: data.piva,
            telefono: data.telefono,
            fax: data.fax,
            email: data.email,
            www: data.www,
            pec: data.pec,
            iban: data.iban,
            codice_sdi: data.codice_sdi,
            cm_01: data.cm_01,
            cm_02: data.cm_02,
            cm_03: data.cm_03,
            cm_04: data.cm_04,
            cm_01_descrizione: data.cm_01_descrizione,
            cm_02_descrizione: data.cm_02_descrizione,
            cm_03_descrizione: data.cm_03_descrizione,
            cm_04_descrizione: data.cm_04_descrizione,
            responsabile_fiera: data.responsabile_fiera,
            responsabile_fiera_telefono: data.responsabile_fiera_telefono,
            responsabile_fiera_cell: data.responsabile_fiera_cell,
            responsabile_fiera_email: data.responsabile_fiera_email,
            prodotti_servizi: data.prodotti_servizi,
            produttore: data.produttore,
            concessionario: data.concessionario,
            distributore: data.distributore,
            rivenditore: data.rivenditore,
            importatore: data.importatore,
            tipologia_espositiva_scelta: data.tipologia_espositiva_scelta,
            q_10: data.q_10, q_11: data.q_11, q_12: data.q_12, q_13: data.q_13,
            importo_area: data.importo_area, imponibile: data.imponibile, iva: data.iva,
            totale: data.totale, anticipo_versato: data.anticipo_versato,
            note: data.note, esigenze_espositive: data.esigenze_espositive,

        });

        console.log("selezionata  " + prop.idRichiesta);

    }



    useEffect(() => {

        console.log("Cambiata pp->riempo i campi  " + prop.idRichiesta);



    }, [prop]);
    /* ---------------------------------pdf---------------------------------------------------------------------*/
  


    async function download_pdf() {
        console.log("download_pdf :" + soggetto.idRichiesta);
        if (soggetto.idRichiesta !== 0) {
            let filename = 'PropostaDiPartecipazioneAgriumbria2025.pdf';
         
            ERService.download_pdf(soggetto.idRichiesta)   
                .then(response => {
                    /*  const disposition = response.headers['content-disposition'];
                     filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
                      if (filename.toLowerCase().startsWith ("utf-8''"))
                         filename = decodeURIComponent(filename.replace("utf-8''", ''));
                      else
                         filename = filename.replace(/['"]/g, '');*/
                    return response.data;
                })
                .then(blob => {

                    var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                    var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                    var url = URL.createObjectURL(blobb);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    a.download = filename;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element  
                })
                .catch(error => {
                    console.error(error);
                });

 /*           let filename = 'StampaRichiesta.pdf';
            var url=    ERService.download_pdf(soggetto.idRichiesta);

                  var a = document.createElement('a');
                  a.href = url;
                  a.target = "_blank";
                  a.download = filename;
                  document.body.appendChild(a); // append the element to the dom
                  a.click();
                  a.remove();*/

        }
    }



    /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */


    const validationSchema = Yup.object().shape({
          pdf_proposta: Yup.mixed() 
          .required("Devi caricare un file")
        .test("fileSize", "Il file è troppo grande o non c'è!", (file) => {
            //if u want to allow only certain file sizes
            return  file && file[0] && file[0].size <= 2000000;
          })
          
    });



    /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */



    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });



    const [submitted, setSubmitted] = useState(false);


    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);



    const inserisci_pp = (formData) => {

        setError(null);

        var data = {
            idRichiesta: prop.idRichiesta,
            id_utente: AuthService.getCurrentUser().id,
            manifestazione: prop.manifestazione,
            pdf_proposta: formData.pdf_proposta,


        };

        if (modo === 'edit') {
            ERService.invia(prop.idRichiesta, data)
                .then(response => {
                    handleResponse(response, 'SENT');
                })
                .catch(errore => {
                    handleError(errore);
                });
        }


    }

    function handleResponse(response, op) {
        console.log(response.data);
        setSubmitted(true);
        navigate("/expo_richieste", { state: { submitted: true, salvato: op } });
    }


    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("EEROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

    const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);


    function handleClose() {
        setOpen(false);
      }
    
      function handleConfirm() {
        setConfirm(true);
        setOpen(false);
        deleteAnag();
      }
    
    
      const handleOpen = () => {
        console.log("OPEN DIALOG");
        setOpen(true);
      }
    
      function handleCloseExistent() {
        setOpenExistent(false);
      }
    
      function handleConfirmExistent() {
        setConfirmExistent(true);
        setOpenExistent(false);
    
      }
    
      const deleteAnag = () => {
        setError(null);
    
        if (modo === 'edit') {
    
          console.log("delete anag");
    
          ERService.remove(prop.idRichiesta)
            .then(response => {
              console.log("handleResponse");
              handleResponse(response, 'DEL');
            })
            .catch(errore => {
              console.log("handleError");
              handleError(errore);
            });
    
        }
      };
    

    /* ------------------------------navigazione -----------------------------------------------------------------*/


    const annulla = () => {


        navigate("/expo_richieste");
    }



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questa richiesta di partecipazione?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card >
                            <HeaderForm titolo={"Proposta di Partecipazione " + prop.manifestazione} modo='edit'  save={handleSubmit(inserisci_pp)} exit={annulla} remove={handleOpen}/>
                            <MDBox pt={1} pb={3} px={3} component="form" role="form">
                                <CardContent>
                                    <MDBox>
                                        <MDBox opacity={1} textAlign="center">

                                        </MDBox>
                                        <MDBox pt={4} pb={3} px={3} >
                                            <MDBox>
                                                <ExpoRichiesteStepper index_attivo={2} />
                                            </MDBox>
                                            <MDBox mt={5}>

                                                <MDBox >
                                                    <MDBox mb={4}>

                                                        <MDButton variant="gradient" color="success" fullWidth>
                                                            <MDTypography variant="h5" fontWeight="regular" sx={{
                                                                color: "#FFFFFF"
                                                            }}>DATI ANAGRAFICI</MDTypography>
                                                        </MDButton>
                                                    </MDBox>

                                                    <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >RAGIONE SOCIALE : {prop.ragione_sociale}</MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >RAPPRESENTANTE LEGALE : {prop.rappresentante_legale}</MDTypography>
                                                        </MDBox>    
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >SEDE LEGALE : {prop.indirizzo} N° {prop.civico} ,{prop.indirizzo2!==""?prop.indirizzo2+",":""} 
                                                                {prop.cap}  {prop.localita} ({prop.provincia}) </MDTypography>
                                                        </MDBox>    
                                                    
                                                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                                                      <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >C.F. : {prop.cf}</MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >P.IVA: {prop.piva} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >TELEFONO : {prop.telefono} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >FAX : {prop.fax} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >WWW : {prop.www}   </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >EMAIL : {prop.email} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >PEC : {prop.pec} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >CODICE SDI : {prop.codice_sdi} </MDTypography>
                                                        </MDBox>
                                                        </MDBox>
                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '2fr 1fr 1fr' }}>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >RESPONSABILE FIERA : {prop.responsabile_fiera}</MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >TEL : {prop.responsabile_fiera_telefono} </MDTypography>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >CELL : {prop.responsabile_fiera_cell} </MDTypography>
                                                        </MDBox>  </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2" fontWeight="regular" >EMAIL(per comunicazioni dirette): {prop.responsabile_fiera_email} </MDTypography>
                                                        </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body2" fontWeight="regular" >IBAN (per l'eventuale restituzione dell'anticipo versato) : {prop.iban}</MDTypography>
                                                    </MDBox>

                                                    <MDBox>
                                                        <MDTypography variant="body2" fontWeight="regular" >CODICI MERCEOLOGICI (in ordine di importanza) : {prop.cm_01} - {prop.cm_01_descrizione} ,{prop.cm_02} - {prop.cm_02_descrizione},
                                                            {prop.cm_03} - {prop.cm_03_descrizione},{prop.cm_04} - {prop.cm_04_descrizione}</MDTypography>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body2" fontWeight="regular" >PRODOTTI/SERVIZI IN ESPOSIZIONE : {prop.prodotti_servizi}</MDTypography>
                                                    </MDBox>
                                                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                        <MDTypography variant="body2" fontWeight="regular" >PRODUTTORE : {prop.produttore ? 'SI' : 'NO'}</MDTypography>
                                                        <MDTypography variant="body2" fontWeight="regular" >CONCESSIONARIO : {prop.concessionario ? 'SI' : 'NO'}</MDTypography>
                                                        <MDTypography variant="body2" fontWeight="regular" >DISTRIBUTORE : {prop.distributore ? 'SI' : 'NO'}</MDTypography>
                                                       
                                                        <MDTypography variant="body2" fontWeight="regular" >RIVENDITORE : {prop.rivenditore ? 'SI' : 'NO'}</MDTypography>
                                                        <MDTypography variant="body2" fontWeight="regular" >IMPORTATORE : {prop.importatore ? 'SI' : 'NO'}</MDTypography>
                                                    </MDBox>
                                                    <MDBox mb={4}>

                                                        <MDButton variant="gradient" color="success" fullWidth>
                                                            <MDTypography variant="h5" fontWeight="regular" sx={{
                                                                color: "#FFFFFF"
                                                            }}>TIPOLOGIA ESPOSITIVA SCELTA</MDTypography>
                                                        </MDButton>
                                                    </MDBox>

                                                    <MDBox>
                                                        <MDBox mb={2} mt={4} >

                                                            {prop.tipologia_espositiva_scelta === '01' &&


                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: 'info.light',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>
                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMNISTRAZIONE</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>

                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            01 - VENDITA PRODOTTI AGROALIMENTARI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={4} md={4} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    2 LATI APERTI</MDTypography></Grid>
                                                                            <Grid item xs={8} md={8} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    12 mq (mt 4x3) - Pad. 7</MDTypography>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 1.150,00

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >   1.150,00 €</MDTypography>
                                                                    </Grid>


                                                                </Grid>
                                                            }

                                                            {prop.tipologia_espositiva_scelta === '02' &&


                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: 'info.light',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>
                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMNISTRAZIONE</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            STAND AL COPERTO
                                                                        </MDTypography>


                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            01 - VENDITA PRODOTTI AGROALIMENTARI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={4} md={4} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={8} md={8} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    16 mq (mt 4x4) - Nuova Area Nord</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 1.200,00
                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >1.200,00 €</MDTypography>
                                                                    </Grid>


                                                                </Grid>




                                                            }
                                                            {prop.tipologia_espositiva_scelta === '03' &&

                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: 'info.light',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>
                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMNISTRAZIONE</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            02 - SOMMINISTRAZIONE PRODOTTI AGROALIMENTARI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={4} md={4} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={8} md={8} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    16 mq (mt 4x4) - Nuova Area Nord</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 2.400,00

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" > € 2.400,00
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}



                                                                </Grid>




                                                            }
                                                            {prop.tipologia_espositiva_scelta === '04' &&

                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: 'info.light',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>
                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMNISTRAZIONE</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            STAND AL COPERTO
                                                                        </MDTypography>


                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            02 - SOMMINISTRAZIONE PRODOTTI AGROALIMENTARI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={4} md={4} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={4} md={4} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={8} md={8} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    32 mq (mt 4x8) - Nuova Area Nord
                                                                                </MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 3.500,00
                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >3.500,00 €</MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                </Grid>

                                                            }
                                                            {prop.tipologia_espositiva_scelta === '05' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    16 mq (mt 4x4)</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 1.400,00

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >1.400 €</MDTypography>
                                                                    </Grid>


                                                                </Grid>


                                                            }
                                                            {prop.tipologia_espositiva_scelta === '06' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    24 mq (mt 4x6)</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 2.300,00
                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >2.300,00 €</MDTypography>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {prop.tipologia_espositiva_scelta === '07' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    1 LATO APERTO</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    32 mq (mt 4x8)</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 2.550,00
                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" > 2.550,00 €</MDTypography>
                                                                    </Grid>

                                                                </Grid>


                                                            }
                                                            {prop.tipologia_espositiva_scelta === '08' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    2 LATI APERTI</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    48 mq (mt 12x4)</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 3.400,00 </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >€ 3.400,00</MDTypography>
                                                                    </Grid>
                                                                </Grid>


                                                            }
                                                            {prop.tipologia_espositiva_scelta === '09' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >

                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    3 LATI APERTI</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    48 mq (mt 6x8)</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            € 4.000,00 </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" > 4.000,00 € </MDTypography>
                                                                    </Grid>

                                                                </Grid>
                                                            }
                                                            {prop.tipologia_espositiva_scelta === '10' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}

                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    3 LATI APERTI</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    64 mq E OLTRE MQ</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            {prop.q_10}</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            x 75€ </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >{prop.q_10 * 75} €</MDTypography>
                                                                    </Grid>




                                                                </Grid>


                                                            }
                                                            {prop.tipologia_espositiva_scelta === '11' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#e91e63',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#e91e63',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            OLTRE MQ</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>

                                                                    {/*---------------------------------------------------------------------------------------------*/}


                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            STAND AL COPERTO
                                                                        </MDTypography>

                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    4 LATI APERTI</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    64 mq E OLTRE MQ</MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            {prop.q_11} </MDTypography>

                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            x 80€ </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >{prop.q_11 * 80}€</MDTypography>
                                                                    </Grid>



                                                                </Grid>


                                                            }
                                                            {prop.tipologia_espositiva_scelta === '12' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#ff9800',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#ff9800',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>AREA AL COPERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>SETTORI MERCEOLOGICI AMMESSI: 11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            La tariffa comprende: area espositiva,estintore, servizio antincendio, energia elettrica pari a 2kw/220 V ogni 48 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                                                                        </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            N°</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                                                                            AREA AL COPERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    Modulo mq 48</MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                </MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            {prop.q_12}

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            x  1.800,00 €

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >{prop.q_12 * 1800}€</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}


                                                                </Grid>

                                                            }
                                                            {prop.tipologia_espositiva_scelta === '13' &&
                                                                <Grid container spacing={2} mb={2} columns={13} sx={{
                                                                    fontSize: 12,
                                                                    boxShadow: 2,
                                                                    border: 2,
                                                                    borderColor: '#ff9800',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                        color: '#ff9800',
                                                                    },
                                                                }}>
                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>AREA ALL'APERTO</MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>SETTORI MERCEOLOGICI AMMESSI: 11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>

                                                                    </Grid>

                                                                    <Grid item sm={13} md={13} lg={13} >
                                                                        <MDTypography variant="caption" fontWeight="regular" >
                                                                            La tariffa comprende: area espositiva, servizio antincendio, energia elettrica pari a 1kw/220 V ogni 50 mq, inserimento a catalogo, inviti, pass espositori, pass auto.    </MDTypography>

                                                                    </Grid>


                                                                    <Grid item sm={2} md={2} lg={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO AREA</MDTypography>
                                                                    </Grid>


                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                                                                            SETTORI AMMESSI</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TIPO DI STAND</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            N°</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            PREZZO UNITARIO</MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="medium" >
                                                                            TOT</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular"
                                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                                                                            AREA ALL'APERTO
                                                                        </MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>
                                                                    </Grid>

                                                                    <Grid item xs={3} md={3} >
                                                                        <Grid container spacing={2} >
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                    Modulo mq 50
                                                                                </MDTypography></Grid>
                                                                            <Grid item xs={6} md={6} >
                                                                                <MDTypography variant="overline" fontWeight="regular" >
                                                                                </MDTypography></Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={1} md={1} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            {prop.q_13}

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="overline" fontWeight="regular" >
                                                                            x  1.000,00 €

                                                                        </MDTypography>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} >
                                                                        <MDTypography variant="button" fontWeight="overline" >{prop.q_13 * 1000} €</MDTypography>
                                                                    </Grid>
                                                                    {/*---------------------------------------------------------------------------------------------*/}


                                                                </Grid>


                                                            }


                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 1fr 1fr 1fr' }}>
                                                   
                                                        <MDTypography variant="body2" fontWeight="regular" >IMPONIBILE : {currencyFormat(prop.imponibile)} €</MDTypography>
                                                        <MDTypography variant="body2" fontWeight="regular" >IVA : {currencyFormat(prop.iva)} €</MDTypography>
                                                        <MDTypography variant="body2" fontWeight="regular" >TOTALE : {currencyFormat(prop.totale)} €</MDTypography>
                                                   <MDTypography variant="body2" fontWeight="regular" >ANTICIPO VERSATO :{currencyFormat(prop.anticipo_versato)} €</MDTypography></MDBox>
                                                
                                            

                                                </MDBox>

                                                <MDBox mb={2}>
                                                </MDBox>



                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>

                                                    <MDTypography variant="body2" fontWeight="regular" >Scarica e stampa la Proposta. Timbra e firma il documento e ricaricalo tramite il pulsante seguente! </MDTypography>
                                                    <MDBox sx={{ flex: '1 1 auto' }} />
                                                    <MDBox ml={2}>
                                                    
                                                        <MDButton variant="outlined" color="success" onClick={download_pdf}  >
                                                            Scarica il pdf
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>
                                                <MDBox mb={2}>
                                                </MDBox>
                                                <MDBox>


                                                    <MDInput
                                                        type="file"
                                                        label="Proposta di partecipazione" fullWidth  required
                                                        autocomplete="off"  {...register('pdf_proposta')}
                                                        name="pdf_proposta" InputLabelProps={{ shrink: true }}

                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    >
                                                        {prop.nome_pdf_proposta}
                                                    </MDTypography>

                                                    {
                                                        errors.pdf_proposta ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pdf_proposta?.message}</span> : ''
                                                    }
                                                </MDBox>
                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                                                    <MDButton variant="outlined" color="success" onClick={annulla} >
                                                        annulla
                                                    </MDButton>
                                                    <MDBox sx={{ flex: '1 1 auto' }} />

                                                    <MDBox ml={2}>
                                                        <MDButton variant="gradient" color="success" onClick={handleSubmit(inserisci_pp)} type="submit"  >
                                                            INVIA
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>

                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </CardContent>
                            </MDBox>
                        </Card>
                    </Grid></Grid></MDBox>
        </DashboardLayout>
    )
}


export default ExpoRichiestaConfirm;