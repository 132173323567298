
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import UtentiService from "services/API/Utenti/UtentiService";
import BasicLayout from "layouts/ErrorBasicLayout";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Alert from '@mui/material/Alert';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import okActivation from "common/assets/images/3858836.jpg";
//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function ResetPasswordFromEmail() {

  let navigate = useNavigate();
  const location = useLocation();

  // user state for form
  const [idUser, setIdUser] = useState(0);
  const [username, setUsername] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  useEffect(() => {
    console.log(location.search);
    const id = new URLSearchParams(location.search).get('id');
    const token = new URLSearchParams(location.search).get('token');

    const verifyToken = async () => {

      UtentiService.verifyToken(id, token)
        .then(response => {
          console.log('Token verified successfully!', response.data);
          setIdUser(response.data.idUtente);
          setUsername(response.data.username);
        })
        .catch(error => {
          console.error('Failed to change password:', error);

          // Redirect the user to an error page or perform other actions
          navigate('/verification-error');

        });
    }

    if (id && token) {
      verifyToken();
    } else {
      console.error('verification id not found!');
      navigate('/verification-error');
    }
  }, [location.search]);



  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

  });


  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });





 const cambia_password = (formData) => {
    console.info('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
    setError(null);

    var data = {
      idUtente: idUser,
      username: username,
      password: formData.password,

    };

    UtentiService.cambia_password(data)
      .then(response => {
        handleResponse(response);
      })
      .catch(errore => {
        handleError(errore);
      });

  };
  function handleResponse(response) {

    console.log(response.data);
    setSubmitted(true);
    reset({
      password: '',
      confirmPassword: '',
    });

  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox mx={2} mt={0} p={2} mb={1} textAlign="center" >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FARFIERA
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>

        <MDBox pt={1} pb={3} px={3} component="form" role="form">
          <CardContent>
            <MDBox>
              <MDBox opacity={1} textAlign="center">
                <MDTypography variant="h5" fontWeight="medium" sx={{ color: "#388e3c" }} mt={1} mb={2} >
                  Reset Password azienda
                </MDTypography>
              </MDBox>

              {((submitted) || (error !== null)) &&
                <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" mb={4}>

                  {(submitted) &&
                    <MDBox textAlign="center">
                      <Alert severity="success">PASSWORD CAMBIATA CON SUCCESSO!!!!</Alert>
                    </MDBox>
                  }
                  {error !== null &&
                    <MDBox textAlign="center">
                      <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                    </MDBox>
                  }
                </MDBox>
              }
              {(!submitted) &&
                <MDBox>
                  <MDBox opacity={1} textAlign="center">
                  <MDTypography variant="h5" color="primary" mt={1}>Inserisci la nuova password!</MDTypography></MDBox>
                  <MDBox mt={4} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                    <MDBox>
                      <MDInput type="password" label="Password" fullWidth id="password" required
                        name="password" {...register('password')} InputLabelProps={{ shrink: true }} />
                      {
                        errors.password ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.password?.message}</span> : ''
                      }
                    </MDBox>
                    <MDBox>
                      <MDInput type="password" label="Conferma Password" fullWidth {...register('confirmPassword')} name="confirmPassword" InputLabelProps={{ shrink: true }} />
                      {
                        errors.confirmPassword ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.confirmPassword?.message}</span> : ''
                      }
                    </MDBox>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit(cambia_password)} type="submit">
                      Cambia Password
                    </MDButton>
                  </MDBox>
                </MDBox>
              }
              <MDBox mt={4} mb={1}>
                <MDButton variant="outlined" color="success" href="/login" fullWidth>
                  Torna al Login
                </MDButton>
              </MDBox>

            </MDBox>
          </CardContent>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPasswordFromEmail;
