

import PageLayout from "common/components/implemented/LayoutContainers/PageLayout";


function Test() {

    return (

        <PageLayout>

        </PageLayout>
    );


}

export default Test;