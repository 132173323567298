  // Function to convert currency string to double using Intl.NumberFormat
  function currencyToDouble(currency) {
   
   if(currency!=null){
    // Creating a NumberFormat object with specified locale and currency
    
    //console.log("currency="+currency);
  
      // Tolgo il punto delle migliaia se c'è
      const normalizedCurrency0 = currency.replaceAll(".", "");
  
      //console.log("normalizedCurrency0="+normalizedCurrency0);
    // Formatting the currency to remove any non-numeric characters except for decimal point
    const normalizedCurrency = normalizedCurrency0.replace(",", ".");
  
    //console.log("normalizedCurrency="+normalizedCurrency);
    // Converting the normalized string to a double value using Number constructor
    const doubleValue = Number(normalizedCurrency);
    //console.log("doubleValue="+doubleValue);
    // Returning the double value
    return doubleValue;
   }else return 0.00;
  }

  export default currencyToDouble