//import http from 'http-common.js';
import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';




const getAllUtenti = () => {
        return axios.get(axios.defaults.baseURL + '/utenti/all', { headers: authHeader() });
}

const getUtenteById = (id) => {
        console.log("getUtenteById= " + id);
        return axios.get(axios.defaults.baseURL + `/utenti/getById/${id}`, { headers: authHeader() });
}

const activate = (id,email) =>{
        console.log("activate= " + id);
        return axios.get(axios.defaults.baseURL + `/utenti/verify-email/${id}/${email}`);
}


const verifyToken = (id,token) =>{
        console.log("verifyToken= " + id);
        return axios.get(axios.defaults.baseURL + `/utenti/verify-token/${id}/${token}`);
}

const cambia_password = (data) => {
        return axios.post(axios.defaults.baseURL + '/utenti/cambia_password', data)
};

const create = (data) => {

        return axios.post(axios.defaults.baseURL + '/utenti/ins', data, {
                headers: authHeader()
        })
};
const createExpo = (data) => {
        return axios.post(axios.defaults.baseURL + '/utenti/signup', data)
};
const update = (id, data) => {
        console.log("update= " + id);
        return axios.post(axios.defaults.baseURL + `/utenti/update/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
        console.log("remove= " + id);
        return axios.post(axios.defaults.baseURL + `/utenti/remove/${id}`, null, { headers: authHeader() });
};

const request_reset_pwd = ( data) => {
        console.log("request_reset_pwd= " + data.email);
        return axios.post(axios.defaults.baseURL + `/utenti/request_reset_pwd`,data);
};

const UtentiService = {
        getAllUtenti,
        getUtenteById,
        request_reset_pwd,
        create, update, remove,
        createExpo,activate,verifyToken,cambia_password
};

export default UtentiService;
