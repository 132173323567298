import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox   from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";

 const ReactHookFormCheckBox = ({
    name,
    label,
    control,
    labelPlacement,
    disabled,
    onChange,
    className
  }) => {
    return (
      <FormControlLabel
        label={label}
        disabled={disabled}
        labelPlacement={labelPlacement}
        className={className}
        control={
          <Controller
            name={name}
            control={control}        
            rules={{ required: true }}
            render={({ field: { value, ...field } }) => (
              <MuiCheckbox {...field} checked={!!value}    onChange={e => {
                field.onChange(e); // This needs to do custom stuff in onChange
                if(onChange!=null && onChange!=='') { onChange(e); // However doing so the change is not registered to hook-form
                }
                }}/>
            )}
          />
        }
      />
    );
  };

  export default ReactHookFormCheckBox;