import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Controller } from "react-hook-form";

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  value,
  children,
  onChange2,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl fullWidth {...props}>   
      <InputLabel id={labelId} shrink={true}>{label}</InputLabel>
      {/*<Controller
       
         render={({field: { onChange, value }})=>(
          <Select labelId={labelId} label={label} notched={true} defaultValue={defaultValue} value={value}
          onChange={onChange}>
            {children}
          </Select>)
        }
        name={name}
        control={control}
        defaultValue={defaultValue}       
      />*/}
 <Controller
       
       render={({field: { value,...field }})=>(
        <Select labelId={labelId} label={label} notched={true} defaultValue={defaultValue} value={value}
        onChange={e => {
          field.onChange(e); // This needs to do custom stuff in onChange
          if(onChange2!=null && onChange2!=='') {  onChange2(e.target.value); // However doing so the change is not registered to hook-form
          }
         
        }}
        >
          {children}
        </Select>)
      }
      name={name}
      control={control}
      defaultValue={defaultValue}       
    />


    </FormControl>
  );
};
export default ReactHookFormSelect;