import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom'
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"

import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";


// Data
import AnagraficaService from "services/API/Anagrafica/AnagraficaService";

const AnagraficaExists = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, subject } = location.state;

    console.log("ANAG EXIST SUBJECT=" + subject);

    const [has_result, setHas_result] = useState(false);
    const [nome, setNome] = useState("");

    const [anagAttributes, setAnagAttributes] = useState([]);


    const anagTableData = {
        columns: [
            { Header: "Tipo", accessor: "tipo", width: "5%", align: "left" },
            { Header: "Denominazione", accessor: "denominazione", width: "15%", align: "left" },
            { Header: "Email", accessor: "email",width: "15%", align: "left" },
            { Header: "Provenienza", accessor: "provenienza",width: "15%", align: "left" },
            { Header: "Professione", accessor: "professione", align: "left" },
            { Header: "action", accessor: "action",width: "5%", align: "center", enableGlobalFilter: false },
        ],

        rows: anagAttributes,


    };

    function retrieveAnagrafiche() {

        (async () => {
            setHas_result(false);


            AnagraficaService.controlla_azienda(nome)
                .then(response => {
                    console.log(response.data);
                    const isDataAvailable = response.data && response.data.length;
                    if (isDataAvailable) {
                        setHas_result(true);
                        let anagrafiche = response.data;
                        const temp = []
                        anagrafiche.forEach(anag => {
                            // console.log(anag.cognome);
                            let denominazione;
                            if (anag.azienda !== '') {
                                denominazione = anag.azienda;
                                if (anag.cognome !== '') denominazione += "(" + anag.cognome + ' ' + anag.nome + ")"
                            } else {
                                denominazione = anag.cognome + ' ' + anag.nome;

                            }
                            let tipo = 'V';
                            if (anag.operatore) tipo += ' O';
                            if (anag.espositore) tipo += 'E';
                            temp.push({
                                tipo: tipo,
                                denominazione: denominazione,
                                email: anag.email,
                                provenienza: anag.comune + '(' + anag.provincia + ')',
                                professione: anag.professione,
                                action: (
                                    <Link to="/anagEdit" state={{ modo: 'edit', soggetto: anag, subject: subject }}
                                    >
                                        <MDButton variant="outlined" color={"success"} iconOnly={true} >
                                            <Icon>edit</Icon>
                                        </MDButton>
                                    </Link>
                                )
                            })

                        });
                        setAnagAttributes(temp);

                    }
                    else {
                        inserisci();

                    }
                })
        })()
    };

    const inserisci = () => {

        const anag = { idAnagrafica: 0, nome: '', cognome: '', azienda: nome,operatore:(subject === "O"?true:false)}

        navigate(`/anagEdit`, { state: { modo: 'new', soggetto: anag, subject: subject, subject: subject } });

    }


    const annulla = () => {
        console.log("ANNULLA + subject=" + subject);
        if (subject === "ALL")
            navigate("/anagrafica");
        if (subject === "O")
            navigate("/operatori");
            if (subject === "V")
            navigate("/visitatori");
        }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3} pb={3} px={3} spacing={6} >

                <Card>
                    <HeaderForm titolo={subject === 'O' ? "Operatori" : "Tutte le anagrafiche"} exit={annulla} modo='' save={annulla} subject={subject} />
                    <MDBox pt={4} pb={3} px={3} >
                        <MDBox>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} mb={2}>
                                    <MDTypography variant="button" fontWeight="regular" color="text"> Inserire Nome e Cognome / Ragione sociale : </MDTypography>
                                </Grid>
                                <Grid item xs={12} sm={12} mb={2} >
                                    <MDInput type="text" label="Azienda" fullWidth name='nome' value={nome} onChange={(e) => { setNome(e.target.value) }} InputLabelProps={{ shrink: true }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2} textAlign="right" >
                                    <MDButton variant="gradient" color="success" onClick={retrieveAnagrafiche} type="submit">Cerca</MDButton>

                                </Grid>


                            </Grid>
                        </MDBox>
                        <MDBox pt={3}>
                            {(has_result ?
                                <MDBox>
                                    <MDTypography variant="button" fontWeight="regular" color="text"> Sono stati trovati i seguenti risultati : </MDTypography>

                                    <DataTable key={anagAttributes}
                                        canSearch={false}
                                        table={anagTableData}
                                        pageSize={20}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={true}
                                        noEndBorder
                                    />
                                    <MDTypography variant="button" fontWeight="regular" color="text">Non è tra questi: </MDTypography>
                                    <MDButton variant="gradient" color="success" onClick={inserisci} type="submit">Avanti</MDButton>
                                </MDBox> : <MDTypography variant="button" fontWeight="regular" color="text"> Non ci sono risultati</MDTypography>)}

                        </MDBox>
                    </MDBox>
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
/*
AnagraficaExists.defaultProps = {
    subject: "ALL",
  
  };
  
  // Typechecking props for the Footer
  AnagraficaExists.propTypes = {
    subject: PropTypes.string,
   
  };*/

export default AnagraficaExists;
