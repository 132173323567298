/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import UtentiService from "services/API/Utenti/UtentiService";
import BasicLayout from "layouts/ErrorBasicLayout";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDButton from "common/components/MD/MDButton";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";
import okActivation from "common/assets/images/3858836.jpg";


function ExpoActivation() {

  let navigate=useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(location.search);
    const id = new URLSearchParams(location.search).get('id');
    const email = new URLSearchParams(location.search).get('email');

    const verifyEmail = async () => {

      UtentiService.activate(id,email) 
        .then(response => {
          console.log('Email verified successfully!', response.data);
        })
        .catch(error => {
          console.error('Failed to verify email:', error);

        // Redirect the user to an error page or perform other actions
        navigate('/verification-error');
  
        });
    }

    if (id && email) {
      verifyEmail();
    } else {
      console.error('Email verification id not found!');
     navigate('/verification-error');
    }
  }, [location.search]);

  return (
    <BasicLayout image={bgImage}>
        <Card>
            <MDBox mx={2}
                mt={0}
                p={2}
                mb={1}
                textAlign="center"
            >
                <Grid container spacing={2}>
                    <Grid item xl={7}>
                       
                        <MDBox><MDTypography variant="h2" color="primary" mt={1}>
                               VERIFICA AVVENUTA CON SUCCESSO!</MDTypography>
                                
                                <MDTypography variant="h4" color="primary" mt={1}>
                                Il tuo account è stato attivato, procedi al login!</MDTypography>    

                                </MDBox>
                                <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="success" href="/login">
           Vai al Login
          </MDButton>
        </MDBox>
                    </Grid>
                    <Grid item xl={5}>
                    <img src={okActivation} width='300px' className="img-fluid" alt="" />
                    </Grid>

                </Grid>
            </MDBox>
        </Card>
    </BasicLayout>
);
}

export default ExpoActivation;
