import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables";

import Alert from '@mui/material/Alert';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Data
import AnagraficaDataService from "services/API/Anagrafica/AnagraficaService";



function ElencoAnagrafiche({subject}) {

  const [anagrafiche, setAnagrafiche] = useState([]);

  useEffect(() => {
    //console.log("retrive op");
    //console.log("USEEFFECT="+{subject}); 
    //retrieveAnagrafiche();
    if(subject==='O'){
     // console.log(subject); 
      AnagraficaDataService.getAllOperatori() 
      .then(response => {
        setAnagrafiche(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
    }else{
      if(subject==='V'){
        console.log(subject); 
        AnagraficaDataService.getAllVisitatori() 
         .then(response => {
          console.log(response.data);
          setAnagrafiche(response.data);
         })
         .catch(e => {
           console.log(e);
         });
       }else{
        if(subject==='E'){
          console.log(subject); 
          AnagraficaDataService.getAllEspositori() 
           .then(response => {
            console.log(response.data);
            setAnagrafiche(response.data);
           })
           .catch(e => {
             console.log(e);
           });
         }else{
      console.log(subject); 
       AnagraficaDataService.getAllAnagrafiche()
      .then(response => {
        console.log(subject); 
        setAnagrafiche(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });}}
    
    
    }
  }, [subject]);



   //per passarmi la variabiule se ho salvato e far comparire l'alert
   const location = useLocation();
   //console.log(location);
 
   const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state
 
  const anagAttributes = []
  anagrafiche.forEach(anag => {
    
    let denominazione;
    if(anag.azienda!=='') {
      denominazione=anag.azienda;
      if(anag.cognome!=='') denominazione+="("+anag.cognome+' '+anag.nome+")"
    }else{
      denominazione=anag.cognome+' '+anag.nome;

    }
    let tipo='';
    if(anag.visitatore) tipo+='V';
    if(anag.operatore) tipo+=' O';
    if(anag.espositore) tipo+= ' E';
    anagAttributes.push({
      tipo:tipo,
      denominazione: denominazione,
      email: anag.email,
      provenienza: anag.localita+'('+anag.provincia+')',
      professione: anag.espositore?anag.cm_1_descrizione:anag.professione,
      action: (
        <Link  to="/anagEdit" state={{modo:'edit',soggetto: anag,subject:subject }}
       >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      )
    })
  });
  const anagTableData = {
    columns: [
      { Header: "Tipo", accessor: "tipo", width: "5%", align: "left" },
      { Header: "Denominazione", accessor: "denominazione", width: "15%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Provenienza", accessor: "provenienza", align: "left" },
      { Header: "Professione", accessor: "professione", align: "left" },
      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: anagAttributes,


  };
  
  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={subject==='O'?"Operatori":
                                    subject==='V'?"Visitatori":
                                      subject==='E'?"Espositori":"Tutte le anagrafiche"} add="/anagExists" modo="vis" subject={subject} />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

<Alert severity="success">Dati Anagrafici salvato con successo!</Alert>}
 {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Anagrafica eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={anagTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
                
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}

ElencoAnagrafiche.defaultProps = {
  subject: "ALL",

};

// Typechecking props for the Footer
ElencoAnagrafiche.propTypes = {
  subject: PropTypes.string,
 
};

export default ElencoAnagrafiche;
