import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper"

import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Checkbox from '@mui/material/Checkbox';

//pdf viewer
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer, SpecialZoomLevel, ViewMode, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


// Data
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';
//script
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";


const ExpoRichiestePDFVis= () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { modo, soggetto, subject } = location.state;

  const [displayPDF, setDisplayPDF] = useState('stringa_iniziale');

  const defaultLayoutPluginInstance = defaultLayoutPlugin(

  );


   
    useEffect(() => {
      if (soggetto != null) {
          console.log("soggetto non nullo: id=" + soggetto.idRichiesta);
          if (soggetto.idRichiesta !== 0) {
              ERService.selezionaPDF(soggetto.idRichiesta)  
                  .then(response => {
                
                
                    var arrrayBuffer = base64ToArrayBuffer(response.data); //data is the base64 encoded string
                    var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                    var url = URL.createObjectURL(blobb);
                console.log("url" + displayPDF);
                setDisplayPDF(url);
      
              });;
    
          }



      } else {
          console.log("soggetto nullo");
      }
  }, [soggetto]);



  const avanti = () => {
  
  }

  const annulla = () => {
    navigate("/expo_richieste");
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} px={3} spacing={6} >

        <Card>
          <HeaderForm titolo="Richiesta di Iscrizione " exit={annulla} modo='' save={annulla} subject="" />
          <MDBox pt={4} pb={3} px={3} >
         
            <MDBox>
            <MDBox pt={4} pb={3} px={3} >

            </MDBox>
            <MDBox >
              {//   <PDFViewer>
                // <Document 
                // file={`data:application/pdf;base64,${file}`}
                //file={filefile}
                //file={{ url: {file_url} }}
                //   loading={'Please wait, the document is loading!'}
                //  onLoadError={console.error}
                // >
                //</PDFViewer>  <Page pageNumber={1} />
                //</MDBox></Document>

                //</PDFViewer>

                //  <MyViewer></MyViewer> 


                //<PDFViewer file ={displayP DF}></PDFViewer> 
              }

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"  >
                <div style={{ height: "720px" }}>
                  {displayPDF !== 'stringa_iniziale' &&
                    <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={displayPDF} defaultScale={SpecialZoomLevel.PageFit}
                      ScrollMode={ScrollMode.Page} ViewMode={ViewMode.SinglePage}
                      initialPage={0} />}
                </div>
              </Worker>


            </MDBox>
         

            <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
   
            <MDBox sx={{ flex: '1 1 auto' }} />



            </MDBox>
          </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
/*
AnagraficaExists.defaultProps = {
    subject: "ALL",
  
  };
  
  // Typechecking props for the Footer
  AnagraficaExists.propTypes = {
    subject: PropTypes.string,
   
  };*/

export default ExpoRichiestePDFVis;
