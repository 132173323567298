import { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';

import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import MDBox from "common/components/MD/MDBox";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Data
import LocDataService from "services/API/Comuni/ComuniService";

function LocalitaEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("STATE+"+location.state.modo);
  //  console.log("STATE+"+location.state.soggetto.email);
    const {modo,soggetto}=location.state;
    
    // user state for form
    const [localita, setLocalita] = useState({ idLocalita:0, cap:'', localita:'',sigla_provincia :'',denominazione_regione:'',nazione:''});

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);


    const validationSchema = Yup.object().shape({
        cap: Yup.string().required('Cap richiesto').max(8, 'Sigla Provincia must not exceed 8 characters'),
        localita: Yup.string().required('Localita richiesto'),
        sigla_provincia: Yup.string().required('Sigla Provincia richiesto').min(2, 'Provincia must be at least 2 characters')
        .max(4, 'Sigla Provincia must not exceed 4 characters'),
          });


    useEffect(() => {
         console.log(modo);
      
      if(soggetto!= null){
        console.log(soggetto.id);
       setLocalita({ idLocalita:soggetto.idLocalita, cap:soggetto.cap, localita:soggetto.localita,
        sigla_provincia :soggetto.sigla_provincia,denominazione_regione:soggetto.denominazione_regione,nazione:soggetto.nazione});}
    }, []);

      // effect runs when user state is updated
      useEffect(() => {
        // reset form with user data
      setValue("cap",localita.cap);
      setValue("localita",localita.localita);
      setValue("sigla_provincia",localita.sigla_provincia);
      setValue("regione",localita.denominazione_regione);
      setValue("nazione",localita.nazione);
    }, [localita]);
    

    const {
        register, //register inputs
        reset, setValue,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

  
    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const insert= (formData) => {
        setError(null);
        var data = {
            cap: formData.cap,
            localita: formData.localita,
            sigla_provincia: formData.sigla_provincia,
            denominazione_regione: formData.regione,
            nazione: formData.nazione,
        };

        if (modo==='new') {
            console.log("Inserisci localiat");
            LocDataService.create(data)
                .then(response => {
                    handleResponse(response,'INS');
                })
                .catch(errore => {
                    handleError(errore);
                });
        } else {
            console.log("update localita");
            LocDataService.update(localita.idLocalita, data)
                .then(response => {
                    handleResponse(response,'MOD');                
                })
                .catch(errore => {
                    handleError(errore);
                });

        }
    };

    function handleClose(){
    
    setOpen(false);
}
    function handleConfirm(){
       
            setConfirm(true);
        

        setOpen(false);

        deleteLoc();
    }

const handleOpen = () => {
    console.log("OPEN DIALOG");
    setOpen(true);
}

    const deleteLoc = () => {
         setError(null);
        
        if (modo ==='edit') {
          
            console.log("delete loc");
            LocDataService.remove(localita.idLocalita)
                .then(response => {
                    console.log("handleResponse");
                    handleResponse(response,'DEL');                
                })
                .catch(errore => {
                    console.log("handleError");
                    handleError(errore);
                });

        
    }
    };


    function handleResponse(response,op) {
        console.log(response.data);
        navigate(`/localita`, { state: { submitted: true, salvato: op} });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);
    
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("ERROR:RESPONSE" + error.response.data);
          setErrorMessage(error.response.data);
    
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log("ERROR:REQUEST" + error.request);
          setErrorMessage(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          setErrorMessage(error.message);
        }
       
      }

      const annulla = () => {
        console.log("ANNULLA");
      navigate("/localita");

    }




    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title"  aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
        Eliminare questo record?
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare questo record?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <MDButton onClick={handleClose}>No</MDButton>
        <MDButton onClick={handleConfirm} autoFocus>
            SI
        </MDButton>
        </DialogActions>
        </Dialog>



            <form >
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <HeaderForm titolo="Località" save={handleSubmit(insert)} remove={handleOpen} exit={annulla} modo={modo} />

                           
                            {error !== null &&
                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                  }
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} mb={2}>

                                            <MDInput type="text" label="Cap" fullWidth name="cap" {...register('cap')} autocomplete="off"
                                                required error={errors.cap ? true : false} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Località" fullWidth id="localita"   required error={errors.localita ? true : false}  autocomplete="off"
                                                name="localita" {...register('localita')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.localita ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.localita?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Sigla Provincia" fullWidth id="sigla_provincia" required  autocomplete="off"
                                                name="sigla_provincia" {...register('sigla_provincia')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.sigla_provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.sigla_provincia?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Regione" fullWidth id="regione" autocomplete="off"
                                                name="regione" {...register('regione')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.regione ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.regione?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Nazione" fullWidth id="nazione" required
                                                name="nazione" {...register('nazione')} InputLabelProps={{ shrink: true }} autocomplete="off" />
                                            {
                                                errors.nazione ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nazione?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                           
                                        </Grid>
                                    </Grid>


                                </MDBox>
                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </form>
        </MDBox>


        <Footer />
    </DashboardLayout>
    );
}

export default LocalitaEdit;