
import { useState, useEffect } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDButton from "common/components/MD/MDButton";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";


import Icon from "@mui/material/Icon";
// Material Dashboard 2 React base styles
import breakpoints from "common/assets/theme/base/breakpoints";

function HeaderForm({ titolo, modo, add, save, remove, exit,subject}) {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
   
 
    const navigate=useNavigate();
   
    //console.log("HEADER FORM SUBJECT:"+subject);
    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const go_add =()=> {
        console.log("link "+add);
        console.log("GO ADD SUBJECT= "+subject);
        navigate(add,{state:{modo:'new',soggetto:null,subject:subject}});
}

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    return (
        <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="info"
        >
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    {/*<MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />*/}
                </Grid>
                <Grid item>

                    <MDTypography variant="h6" color="white">
                        {modo==='new'?"Nuovo "+titolo: titolo}
                    </MDTypography>

                </Grid>
                <Grid item sx={{ ml: "auto" }}>
              
                    <AppBar position="static">
                    <MDBox   sx={{
           bgcolor: '#FFFFFF',
          borderRadius: 3,
          py:-2,px:-2
          }
        }><Toolbar> 
                        {/*<Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>*/}
                            {modo === 'vis' &&
                             <MDButton variant="outlined" color="dark" onClick={go_add} type="submit">
                             <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                 add
                             </Icon>
                             &nbsp;Nuovo
                         </MDButton>
                         
                                }       
                            {(modo === "new" || modo === "edit") &&

                                <MDButton variant="outlined" color="success" onClick={save}>
                                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                        save
                                    </Icon>
                                    &nbsp;Salva
                                </MDButton>

                            }
                                 {(modo === "edit") && 
                             <MDButton variant="outlined" color="error"  onClick={remove} sx={{ml:2}}> 
                             <Icon fontSize="small" sx={{ mt: -0.25 }}>
                             delete
                             </Icon>
                             &nbsp;Elimina
                         </MDButton>
                     }
                            {(modo === "new" || modo === "edit" || modo=== "") && 
                             <MDButton variant="outlined" color="secondary"  onClick={exit}  sx={{ml:2}}>
                             <Icon fontSize="small" sx={{ mt: -0.25 }}>
                             exit_to_app
                             </Icon>
                             &nbsp;Esci senza salvare
                         </MDButton>
                     }
                        </Toolbar></MDBox>
                    </AppBar>
       
                </Grid>
            </Grid>
        </MDBox>
   

    );
}

HeaderForm.defaultProps = {
    titolo: "Titolo",
    modo: "vis",
    subject:"ALL",
    add: "",
    save: "",
    exit: "",

};

// Typechecking props for the Footer
HeaderForm.propTypes = {
    titolo: PropTypes.string,
    modo: PropTypes.string,
    add: PropTypes.string,
    subject: PropTypes.string,
    save: PropTypes.func,
    remove:PropTypes.func,
    exit: PropTypes.func
};



export default HeaderForm;